import React from "react";
import InputPosDetailsComponent from "./add-edit-components/input-pos-details";

const PosDetailsComponent = ({ pageMode }) => {
  // let content;

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputPosDetailsComponent />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputPosDetailsComponent />;
  //     break;

  //   default:
  //     content = <ViewPosDetailsComponent />;
  //     break;
  // }

  return (
    <div>
      <InputPosDetailsComponent />
    </div>
  );
};

export default PosDetailsComponent;
