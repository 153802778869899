import locale from "localization/en";

export const productsColumn = [
  {
    key: "select",
    className: "alignToTop",
    width: "50px",
    actionHeader: true,
  },
  {
    key: "sku",
    text: locale.sku,
    className: "alignToTop",
  },
  {
    key: "productName",
    text: locale.productName,
    className: "alignToTop",
    width: "30%",
  },
  {
    key: "variant",
    text: locale.variant,
    className: "alignToTop",
  },
  {
    key: "productType",
    text: locale.productType,
    className: "alignToTop",
  },
];

export const stationProductsColumn = [
  {
    key: "select",
    className: "alignToTop",
    width: "50px",
    actionHeader: true,
  },
  {
    key: "productName",
    text: locale.productName,
    className: "alignToTop",
    width: "30%",
  },
  {
    key: "variant",
    text: locale.variant,
    className: "alignToTop",
  },
  {
    key: "productType",
    text: locale.productType,
    className: "alignToTop",
  },
  {
    key: "amount",
    text: locale.defaultPrice,
    className: "alignToTop",
  },
];

export const stationViewDryStocksColumns = [
  {
    key: "productName",
    text: locale.product,
    className: "alignToTop",
    width: "30%",
  },
  {
    key: "variant",
    text: locale.variant,
    className: "alignToTop",
  },
  {
    key: "productType",
    text: locale.productType,
    className: "alignToTop",
  },
  {
    key: "amount",
    text: locale.defaultPrice,
    className: "alignToTop",
  },
  {
    key: "overridePrice",
    text: locale.overridePrice,
    className: "alignToTop",
  },
];
