import { ApiPath } from "enums";
import { Get } from "utils";

export const getProductsApi = async (query) => {
  const res = await Get(`${ApiPath.Product}`, query);
  return res.data;
};

export const getDryStocksByStationCodeApi = async (query) => {
  const res = await Get(`${ApiPath.Stock}/dry-stock/admin`, query);
  return res.data;
};
