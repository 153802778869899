import classNames from "classnames";
import React from "react";
import { Text } from "..";
import styles from "./field.module.scss";

const Field = ({
  children,
  childrenClassName,
  labelClassName,
  label,
  horizontal,
  className,
  message,
  error,
  labelPosition,
  subLabel,
  style,
}) => {
  return (
    <div
      style={style}
      className={classNames(className, styles.container, {
        [`${styles.horizontal}`]: horizontal,
        [`${styles.labelLeft}`]: labelPosition === "left",
        [`${styles.leftAligned}`]: labelPosition === "left-aligned",
      })}
    >
      {label && (
        <div className={classNames(styles.title, labelClassName)}>
          <Text className={classNames(labelClassName)} label>
            {label}
          </Text>
          {subLabel && (
            <Text italic subtitle>
              {subLabel}
            </Text>
          )}
        </div>
      )}
      <div className={classNames(styles.formContainer, childrenClassName)}>
        {children}
        {message && (
          <Text
            className={classNames(styles.message, {
              [`${styles.error}`]: error,
            })}
          >
            {message}
          </Text>
        )}
      </div>
    </div>
  );
};

export default Field;
