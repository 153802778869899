import React from "react";
import styles from "./select-promo-modal.module.scss";
import { Field, Filter, SearchField, Autocomplete, Button } from "components/commons";
import locale from "localization";
import { Close } from "@material-ui/icons";
import { prettifyPromoType } from "utils/pretty.utils";
import { PromoTypeEnum } from "enums";

const SelectPromosFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  actionText,
  actionProps,
  actionOnClick,
  placeholder = "",
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            label={locale.promoType}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"promoType"}
              hasAll
              multiple
              options={[
                // {
                //   label: prettifyPromoType(PromoTypeEnum.DiscountPerLiter),
                //   value: PromoTypeEnum.DiscountPerLiter,
                // },
                // {
                //   label: prettifyPromoType(PromoTypeEnum.DiscountPerTransaction),
                //   value: PromoTypeEnum.DiscountPerTransaction,
                // },
                {
                  label: prettifyPromoType(PromoTypeEnum.DiscountPerUnit),
                  value: PromoTypeEnum.DiscountPerUnit,
                },
                // {
                //   label: prettifyPromoType(PromoTypeEnum.PercentDiscount),
                //   value: PromoTypeEnum.PercentDiscount,
                // },
              ]}
              {...filterState.promoType}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={placeholder}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        {actionText && (
          <Field className={styles.clearSelectedButton}>
            <Button onClick={actionOnClick} link {...(actionProps || {})}>
              {actionText}
              <Close fontSize="small" />
            </Button>
          </Field>
        )}
      </div>
    </div>
  );
};

export default SelectPromosFilter;
