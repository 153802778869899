import { Path } from "enums";
import StationDetailsModule from "modules/station/station-details/station-details.module";
import StationsModule from "modules/station/stations.module";
import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const StationsPage = () => {
  return (
    <Switch>
      <Route exact path={Path.Stations} component={StationsModule} />
      <Route path={Path.AddStation} component={StationDetailsModule} />
      <Route path={Path.StationDetails} component={StationDetailsModule} />

      {/* <Redirect to={Path.Stations} /> */}
    </Switch>
  );
};

export default StationsPage;
