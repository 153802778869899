import { Autocomplete, Field, TextField, TimePickerRange, Title } from "components/commons";
import styles from "../promo-details.module.scss";
import locale from "localization";
import { PromoTypeEnum } from "enums";
import { prettifyPromoType } from "utils/pretty.utils";
import { InputAmount } from "components/field";
import DateRange from "components/commons/date-range/date-range";
import Validation from "utils/validation.utils";
import { combineDateAndTimeInput, roundUp } from "utils";
import { validateField } from "hooks/useForm";
import { isDate } from "lodash";
import { getMinTime } from "components/commons/time-picker/time-picker-range";
import { today12AMtime } from "../add-edit-promo-form-state";

const validationsByPromoType = {
  [PromoTypeEnum.PercentDiscount]: [
    Validation.required(),
    Validation.min(0.01, { isFormatted: true }),
    Validation.max(100, { isFormatted: true }),
    Validation.rangeValue(0.01, 100),
  ],
  [PromoTypeEnum.DiscountPerTransaction]: [
    Validation.required(),
    Validation.maxlength(11),
    Validation.min(0.01, { isFormatted: true }),
  ],
};

const PromoDetailsInputs = (rootForm) => {
  const { fields, modifyField, modifyForm } = rootForm;
  const promoType = fields.promoType.value;

  const onChangePromoAmount = (name, { value }) => {
    modifyField("transactionDiscount", {}, (field) => {
      if (!field) return {};

      if (promoType === PromoTypeEnum.PercentDiscount) {
        value = !value || value[0] === "." ? "" : value;
        value = roundUp(value);
        value = value > 100 || field.value > 100 ? 100 : value;
      }

      field.validations = validationsByPromoType[promoType];
      field.value = value;
      field = validateField(field, fields);

      return field;
    });
  };

  return (
    <div>
      <Title className={styles.subtitle}>{locale.promoDetails}</Title>
      <div className={styles.container}>
        <div>
          <Field
            label={locale.promoDuration}
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <DateRange
              placeholder={locale.promoDate}
              {...fields?.promoDate}
              onChange={(name, props) => {
                const startTime = combineDateAndTimeInput(
                  props?.value?.startDate,
                  fields?.startTime?.value,
                );
                const endTime = combineDateAndTimeInput(
                  props.value?.endDate,
                  fields.endTime?.value,
                );
                const minTime = getMinTime({
                  startTime,
                  endTime,
                  minutesGap: 30,
                  currentTime: new Date(),
                  endDate: props.value?.endDate,
                });

                let endTimeValidations = [Validation.required()];
                if (minTime) {
                  endTimeValidations.push(
                    Validation.minDate({
                      startDate: minTime,
                    }),
                  );
                }

                modifyForm({
                  [fields?.startTime?.name]: {
                    value: today12AMtime(),
                  },
                  [fields?.endTime?.name]: {
                    value: null,
                    validations: endTimeValidations,
                    error: isDate(minTime),
                  },
                  [name]: {
                    value: props.value,
                  },
                });
              }}
            />
          </Field>
          <Field
            label={locale.promoTime}
            className={styles.date}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <TimePickerRange
              minutesGap={30}
              startTimeProps={{
                ...fields.startTime,
                actions: { cancel: true, ok: true },
                disabled: !fields.promoDate?.value?.startDate,
                date: fields.promoDate?.value?.startDate,
                onChange: (name, { value, minTime }) => {
                  value = isDate(value)
                    ? combineDateAndTimeInput(
                        fields.promoDate?.value.startDate || new Date(),
                        value,
                      )
                    : null;

                  let endTimeValidations = [Validation.required()];
                  if (minTime) {
                    endTimeValidations.push(
                      Validation.minDate({
                        startDate: minTime,
                      }),
                    );
                  }

                  modifyForm({
                    [name]: { value: value },
                    endTime: {
                      validations: endTimeValidations,
                    },
                  });
                },
              }}
              endTimeProps={{
                ...fields.endTime,
                actions: { cancel: true, ok: true },
                disabled: !fields.promoDate?.value?.endDate,
                date: fields.promoDate?.value?.endDate,
                onChange: (name, { value, error }) => {
                  value = isDate(value)
                    ? combineDateAndTimeInput(fields.promoDate?.value?.endDate, value)
                    : null;

                  modifyForm({
                    [name]: { value: value },
                  });
                },
              }}
            />
          </Field>
          <Field
            label={locale.promoName}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <TextField {...fields?.promoName} onChange={modifyField} />
          </Field>
          <Field
            label={locale.promoType}
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              {...fields?.promoType}
              options={[
                {
                  label: prettifyPromoType(PromoTypeEnum.DiscountPerTransaction),
                  value: PromoTypeEnum.DiscountPerTransaction,
                },
                {
                  label: prettifyPromoType(PromoTypeEnum.DiscountPerUnit),
                  value: PromoTypeEnum.DiscountPerUnit,
                },
                {
                  label: prettifyPromoType(PromoTypeEnum.PercentDiscount),
                  value: PromoTypeEnum.PercentDiscount,
                },
              ]}
              onChange={modifyField}
            />
          </Field>
          {promoType && !fields?.minPurchase?.hidden && (
            <Field
              label={locale.minPurchase}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <InputAmount
                {...fields?.minPurchase}
                onChange={modifyField}
                isPriceValue
                max={999999.99}
              />
            </Field>
          )}
          {promoType && !fields?.transactionDiscount?.hidden && (
            <Field
              label={locale.promoAmount}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <InputAmount
                {...fields?.transactionDiscount}
                value={fields?.transactionDiscount?.value}
                onChange={onChangePromoAmount}
                decimalPlaces={promoType === PromoTypeEnum.PercentDiscount ? 0 : 2}
                className={styles.amount}
                innerLabel={!promoType || promoType !== PromoTypeEnum.PercentDiscount}
                suffix={
                  promoType === PromoTypeEnum.PercentDiscount && (
                    <div className={styles.labelContainer}>%</div>
                  )
                }
              />
            </Field>
          )}
          {promoType && !fields?.transactionCap?.hidden && (
            <Field
              label={locale.maxDiscount}
              labelPosition={"left"}
              childrenClassName={styles.fieldContent}
            >
              <InputAmount
                {...fields?.transactionCap}
                onChange={modifyField}
                isPriceValue
                max={999999.99}
              />
            </Field>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoDetailsInputs;
