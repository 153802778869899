import React from "react";
import InputStationDetailsComponent from "./add-edit-components/input-station-details";

const StationDetailsComponent = ({ pageMode }) => {
  // let content;

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputStationDetailsComponent />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputStationDetailsComponent />;
  //     break;

  //   default:
  //     content = <ViewStationDetailsComponent />;
  //     break;
  // }

  return (
    <div>
      <InputStationDetailsComponent />
    </div>
  );
};

export default StationDetailsComponent;
