import React from "react";
import InputUsersComponentV2 from "./add-edit-components/input-users-v2";
import { FormMode } from "enums";
import { useFormContext } from "contexts/form-context";
import ViewUsersComponent from "./view-components/view-users";

const UsersComponent = ({ pageMode }) => {
  const { fields } = useFormContext()
  // let content;

  // const [users, setUsers] = useState([]);

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputUsersComponent users={users} setUsers={setUsers} />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputUsersComponent />;
  //     break;

  //   default:
  //     content = <ViewUsersComponent />;
  //     break;
  // }

  if (pageMode === FormMode.View) {
   return  <ViewUsersComponent users={fields?.users.value || []}/>
  }

  // return <InputUsersComponent />;
  return <InputUsersComponentV2/>
};

export default UsersComponent;
