import { useCallback, useContext } from "react";
import useApi from "./useApi";
import { AppContext } from "contexts";
import { getPromoListApi } from "apis";
import PromoTypeEnum, { PromoTypeToApiEnum } from "enums/promo-type.enum";

const usePromos = (props) => {
  const { allPromos, setAllPromos } = useContext(AppContext);

  const getAllPromos = useApi({
    api: getPromoListApi,
    key: props?.key,
  });

  const fetch = useCallback(
    async (props) => {
      const res = await getAllPromos?.request(
        {
          page: 1,
          perPage: 1000,
          discountType: PromoTypeToApiEnum[PromoTypeEnum.DiscountPerUnit],
          status: props?.status && props?.status?.length > 0 ? props.status : undefined,
        },
        () => 0,
        { useLoader: true }
      );

      const promos = res.promos;
      if (props?.filterResult && typeof props?.filterResult === "function") {
        setAllPromos(props.filterResult(promos));
      } else {
        setAllPromos(promos);
      }

      return promos;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    fetch,
    allPromos,
    loading: getAllPromos.loading,
  };
};

export default usePromos;
