const AccessAuthorization = "/access-authorization";
const Promos = "/promos";
const Stations = "/stations";
const Path = {
  Slash: "/",
  Auth: "/auth",
  AccessAuthorization: AccessAuthorization,
  UserAccess: `${AccessAuthorization}/user-access`,
  Stations: Stations,
  AddStation: `${Stations}/add`,
  EditStation: (id, mode) => `${Stations}/details/${id}/${mode}`,
  ViewStation: (id, mode) => `${Stations}/details/${id}/${mode}`,
  StationDetails: `${Stations}/details/:id/:mode`,
  PromosList: Promos,
  AddPromo: `${Promos}/add`,
  PromoDetails: `${Promos}/details/:id/:mode`,
};

export default Path;
