import { Chip as MUIChip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Chip = styled(MUIChip)(({ theme, status, style }) => ({
  ...style,
  backgroundColor: status === "success" ? "#4fa986" : status === "error" ? "#f45047" : undefined,
  color: "#ffffff", // Text color set to white
  fontWeight: 500, // Font weight set to 500
}));

export default Chip;
