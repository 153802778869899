import React from "react";
import MaterialModal from "@material-ui/core/Modal";
import styles from "./modal.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import Title from "../title/title";
import { Text } from "components/commons";

const Modal = ({
  active,
  close,
  title,
  titleStyle,
  subtitle,
  contentStyle,
  children,
  closable = true,
  className,
  closeOnDimmer = false,
  removePadding = false,
  style,
  containerStyle,
  onClickBody,
}) => {
  return (
    <MaterialModal
      open={active}
      onClose={closeOnDimmer || closable ? close : null}
      className={classNames(styles.container, containerStyle, {
        [`${styles.removePadding}`]: removePadding,
      })}
    >
      <div style={style} className={classNames(styles.content, className)} onClick={onClickBody}>
        <div className={styles.header}>
          <div className={styles.textHeader}>
            {title ? (
              <Title small className={classNames(styles.title, titleStyle)}>
                {title}
              </Title>
            ) : null}
            <Text subtitle>{subtitle}</Text>
          </div>
          {closable && (
            <div className={styles.close} onClick={() => close()}>
              <CloseIcon className={styles.icon} />
            </div>
          )}
        </div>
        <div className={classNames(styles.modalContent, contentStyle)}>{children}</div>
      </div>
    </MaterialModal>
  );
};

export default Modal;
