import { Checkbox, Product, Text, PopOver } from "components/commons";
import { formatAmount, formatDate } from "utils";
import { locale } from "localization/en";
import { PromoTypeToApiEnum } from "enums/promo-type.enum";
import styles from "./select-promo-modal.module.scss";
import { prettifyProductCode, prettifyPromoType } from "utils/pretty.utils";
import { ProductCodeEnum } from "enums";
import { sortProductsBySku } from "modules/promos/promo-details/add-edit-components/product-input";

export const mapDataToList = ({ promo, onCheck, selected, disableAction }) => {
  const textProps = {
    className: styles.textStyle,
    color: "#34384b",
  };

  const renderProduct = () => {
    const products = sortProductsBySku(promo?.promoDetails, "name").map(
      ({ sku, name, variant, value }, i) => ({
        product: Object.values(ProductCodeEnum).includes(sku) ? (
          <Product
            key={`${sku}-${i}`}
            grass={sku === ProductCodeEnum.GAS91}
            salsa={sku === ProductCodeEnum.GAS95}
            deepBlue={sku === ProductCodeEnum.GAS97}
            cheddar={sku === ProductCodeEnum.DIESEL}
            // purple={!Object.values(ProductCodeEnum).includes(sku)}
          >
            <Text {...textProps}>{name || prettifyProductCode(sku)}</Text>
          </Product>
        ) : (
          <Text {...textProps}>{name || prettifyProductCode(sku)}</Text>
        ),
        variant: <Text {...textProps}>{variant && variant !== "NULL" ? variant : "--"}</Text>,
        value: (
          <Text key={`${value}-${i}`} {...textProps}>
            {formatAmount(value || 0, "₱")}
          </Text>
        ),
      })
    );

    return (
      <PopOver
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        arrowClassNames={styles.arrow}
        contentStyle={{
          boxShadow: "10px 0px 20px rgba(0, 0, 0, 0.1)",
          overflowY: "scroll",
          width: "480px",
          padding: "5px 0px",
          margin: "10px",
          height: "179px",
        }}
        className={styles.popoverContainer}
        content={
          <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "10px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", width: "50%" }}>
                  <Text
                    strong
                    {...textProps}
                    style={{
                      paddingRight: "1em",
                    }}
                  >
                    {locale.product}
                  </Text>
                </th>
                <th style={{ textAlign: "left" }}>
                  <Text strong {...textProps}>
                    {locale.productVariant}
                  </Text>
                </th>
                <th style={{ textAlign: "left" }}>
                  <Text strong {...textProps}>
                    {locale.promoAmount}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map(({ product, variant, value }) => (
                <tr>
                  <td
                    style={{
                      paddingRight: "1em",
                    }}
                  >
                    <div>{product}</div>
                  </td>
                  <td>
                    <div>{variant}</div>
                  </td>
                  <td>
                    <div>{value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      >
        <div className="link" style={{ color: "slateblue" }}>
          <b>{locale.view}</b>
        </div>
      </PopOver>
    );
  };

  let row = {
    select: (
      <Checkbox
        name={promo?.name}
        value={selected?.includes(promo.promoId)}
        onChange={(name, { value }) => onCheck?.(promo.promoId)}
      />
    ),
    promoName: promo.name,
    promoType: prettifyPromoType(PromoTypeToApiEnum[promo.discountType]),
    promoCap: formatAmount(promo.transactionCap),
    promoDate: `${formatDate(promo.startDate)} - ${formatDate(promo.endDate)}`,
    product: <div>{renderProduct() || "--"}</div>,
    // promoAmount: formatAmount(promo.transactionCap),
  };

  if (disableAction) {
    delete row.select;
  }
  return row;
};

export const mapFilterToRequest = (filterState) => {
  const { promoType, status, ...fs } = filterState;

  if (promoType.value.length > 0) {
    fs.discountType = promoType.value.map((value) => PromoTypeToApiEnum[value]).join(",");
  }

  if (promoType?.isSelectedAll) {
    fs.type = undefined;
  }

  if (status?.value?.length > 0) {
    fs.status = status.value.join(",");
  }

  if (status?.isSelectedAll) {
    fs.status = undefined;
  }

  return fs;
};
