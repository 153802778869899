import { Checkbox, Product } from "components/commons";
import { InputAmount } from "components/field";
import { ProductCodeEnum } from "enums";
import { isNumber } from "lodash";
import { RenderInput } from "modules/station/station-details/station-details.module";
import { formatAmount } from "utils";
import { prettifyProductType } from "utils/pretty.utils";

export const mapDataToList = ({
  product,
  coloredProduct = true,
  onCheck,
  selected,
  disableAction = false,
  handleOverridePriceOnChange,
}) => {
  const overridePriceError =
    !isNumber(+product?.overridePrice) || +product?.overridePrice > 999999.99;

  let mapper = {
    select: (
      <Checkbox
        name={product?.name}
        value={selected?.includes(product.sku)}
        onChange={(name, { value }) => onCheck?.(product.sku)}
      />
    ),
    sku: product.sku,
    productName: coloredProduct ? (
      <Product
        key={product.sku}
        grass={product.sku === ProductCodeEnum.GAS91}
        salsa={product.sku === ProductCodeEnum.GAS95}
        deepBlue={product.sku === ProductCodeEnum.GAS97}
        cheddar={product.sku === ProductCodeEnum.DIESEL}
        purple={!Object.values(ProductCodeEnum).includes(product.sku)}
      >
        {product.name}
      </Product>
    ) : (
      product.name
    ),
    variant: product.variant || "--",
    productType: prettifyProductType(product?.type),
    productPrice: product?.amount ? formatAmount(product?.amount) : "--",
    amount: product?.amount ? formatAmount(product?.amount) : "--",
    overridePrice: (
      <div style={{ width: "110px" }}>
        <RenderInput
          decimalPlaces={2}
          error={product?.error || overridePriceError}
          value={product?.overridePrice}
          name={product.sku}
          innerLabel
          isPriceValue
          max={999999.99}
          onChange={(name, { value }) => handleOverridePriceOnChange?.(name, { value })}
          Component={InputAmount}
        />
      </div>
    ),
    // overridePrice: product?.amount ? formatAmount(product?.amount) : "--"
  };
  if (disableAction) {
    delete mapper.select;
  }
  return mapper;
};

export const mapFilterToRequest = (filterState) => {
  const { productType, ...fs } = filterState;

  if (productType?.value?.length > 0) {
    fs.type = productType?.value?.join(",");
  }

  if (productType?.isSelectedAll) {
    fs.type = undefined;
  }

  return fs;
};
