import { formatNumber, parseNumber } from "utils";
import { isNumber } from "lodash";
import { TextField } from "components/commons";
import styles from "./input-amount.module.scss";
import classNames from "classnames";
const QuantitySelector = ({
  className,
  onChange,
  innerLabel,
  decimalPlaces = 2,
  isPriceValue = false,
  prefix = "-",
  suffix = "+",
  onBlur,
  disabled,
  ...props
}) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      let isNum = /^\d+$/.test(value);
      if (!isNum) {
        return
      }

      if (props?.max && +value > props.max) {
        return;
      }

      if (props?.min && +value < props.min) {
        return;
      }

      onChange(name, { value });
    },
    onBlur: (name, { value }) => {
      if (onBlur) {
        onBlur(value);
      } else {
        if (value) {
          onChange(name, {
            value: `${formatNumber(value, 0)}${!innerLabel ? "" : ""}`,
          });
        } else {
          // onChange(name, { value: `${formatNumber(0, decimalPlaces)}` });
        }
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: isNumber(num) ? num : "" });
      }
    },
  };

  return (
    <TextField
      {...componentProps}
      readOnly
      className={styles.quantitySelector}
      disabled={disabled}
      prefix={
        <div
          onClick={() =>
            componentProps?.onChange?.(componentProps?.name, { value: +componentProps?.value - 1 })
          }
          className={classNames(styles.labelContainer, {
            [`${styles.disabled}`]: disabled,
          })}
        >
          {prefix}
        </div>
      }
      suffix={
        suffix && (
          <div
            onClick={() =>
              componentProps?.onChange?.(componentProps?.name, {
                value: +componentProps?.value + 1,
              })
            }
            className={classNames(styles.suffix, {
              [`${styles.disabled}`]: disabled,
            })}
          >
            {suffix}
          </div>
        )
      }
    />
  );
};

export default QuantitySelector;
