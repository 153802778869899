import locale from "localization/en";

export const promosColumn = [
  {
    key: "select",
    className: "alignToTop",
    width: "50px",
    actionHeader: true,
  },
  {
    key: "promoName",
    text: locale.promoName,
    className: "alignToTop",
    width: "30%",
  },
  {
    key: "promoType",
    text: locale.promoType,
    className: "alignToTop",
    width: "15%",
  },
  {
    key: "promoCap",
    text: locale.promoCap,
    className: "alignToTop",
    width: "100px",
  },
  {
    key: "product",
    text: (
      <span >
        {locale.product} /<br />
        {locale.promoAmount}
      </span>
    ),
    className: "alignToTop",
    width: "15%",
    // width: "10%",
  },
  {
    key: "promoDate",
    text: locale.promoDate,
    className: "alignToTop",
    width: "200px",
  },
  // {
  //   key: "promoAmount",
  //   text: locale.promoAmount,
  //   className: "alignToTop",
  //   width: "100px",
  // },
];
