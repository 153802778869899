import React, { useState, useMemo, useEffect } from "react";
import SearchField from "./search-field";
import styles from "./search-field.module.scss";
import Text from "../text/text";
import SearchIcon from "@material-ui/icons/Search";
import locale from "localization";

const SearchFieldV2 = ({ onChange, value, options, ...props }) => {
  const [suggestion, setSuggestion] = useState([]);
  const suggestionsList = useMemo(() => options || ["juan.dc", "john.doe", "jane.doe"], [options]); // Example suggestions

  const handleChange = (name, { value }) => {
    onChange?.(name, { value });
  };

  useEffect(() => {
    if (value && !suggestionsList.includes(value)) {
      // Find a suggestion that starts with the input value
      const matchedSuggestion = suggestionsList.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );

      setSuggestion(matchedSuggestion || []);
    }
  }, [suggestionsList, value]);

  const handleClick = (item) => {
    onChange?.(props?.name, { value: item });
    setSuggestion([]); // Clear suggestions after selection
  };

  const handleOnEnter = () => {
    if (value?.length > 0 && suggestion.length > 0) {
      onChange?.(props?.name, { value: suggestion[0] });
      setSuggestion([]);
    }
  };

  return (
    <div className={styles.searchFieldV2}>
      <SearchField
        {...props}
        searchWithSymbols
        value={value}
        onChange={handleChange}
        onEnter={handleOnEnter}
      />
      {suggestion.length > 0 && value && (
        <div className={styles.suggestion}>
          {suggestion.map((item, index) => (
            <div key={index} onClick={() => handleClick(item)}>
              <div>
                <SearchIcon className={styles.searchIcon} />
                <Text color={"#000"}>{item}</Text>
              </div>
              {index === 0 && <i>{locale.tapEnterToCreate}</i>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchFieldV2;
