import React from "react";
import styles from "../../station-details.module.scss";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import classNames from "classnames";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import { prettifyStationPosition } from "utils/pretty.utils";

const ViewUsersComponent = ({ users }) => {
  const cols = [
    {
      key: "username",
      text: locale.username,
    },
    // {
    //   key: "firstName",
    //   text: locale.firstName,
    // },
    // {
    //   key: "lastName",
    //   text: locale.lastName,
    // },
    {
      key: "name",
      text: locale.name,
    },
    {
      key: "position",
      text: locale.position,
    },
  ];

  const mapDataToList = ({ user }) => {
    return {
      username: user?.username,
      // firstName: user?.firstName,
      // lastName: user?.lastName,
      name: user?.name,
      position: prettifyStationPosition(user?.position),
    };
  };

  const { table } = useDataTable({
    value: users || [],
    filter: { initialState: {}, mapper: (fs) => fs },
    table: {
      key: "station-users",
      mapper: (user) => mapDataToList({ user }),
      columns: cols,
    },
  });
  return (
    <div className={classNames(styles.container, styles.viewUsersTable)}>
      <DataTableV2 {...table} tableVersion="v2" hideNoSearchResultRender hidePagination />
    </div>
  );
};

export default ViewUsersComponent;
