import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core/";
import { Title } from "components/commons";
import styles from "./accordion.module.scss";
const CustomAccordion = ({ label, subLabel, subLabelStyle, children, defaultExpanded }) => {
  return (
    <div>
      <Accordion
        style={{
          backgroundColor: "none",
          boxShadow: "none",
          // borderBottom: "0.2px solid gray",
        }}
        defaultExpanded={defaultExpanded}
      >
        <div style={{ borderBottom: "2px solid #e0e0e0" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
            aria-controls="panel1-content"
          >
            <div className={styles.heading}>
              <Title style={{ fontSize: "16px", color: "black" }}>{label}</Title>
              <Title style={{ fontSize: "14px", color: "#a6aab3", ...(subLabelStyle || {}) }}>
                {subLabel}
              </Title>
            </div>
          </AccordionSummary>
        </div>

        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
