import React from "react";
import styles from "../../station-details.module.scss";
import useDataTable from "hooks/useDataTable";
import { initialFilterState } from "components/modals/select-products/select-products-filter-state";
import {
  mapDataToList,
  mapFilterToRequest,
} from "components/modals/select-products/select-products-mapper";
import { stationViewDryStocksColumns } from "components/modals/select-products/select-products-columns";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { sortProductsBySku } from "modules/promos/promo-details/add-edit-components/product-input";
import classNames from "classnames";

const ViewDryStocksComponent = ({ dryStocks, handleOverridePriceOnChange, isView = false }) => {
  const { table } = useDataTable({
    value: sortProductsBySku(dryStocks || []),
    filter: { initialState: initialFilterState(), mapper: mapFilterToRequest },
    table: {
      key: "station-products",
      mapper: (product) =>
        mapDataToList({ product, disableAction: true, handleOverridePriceOnChange }),
      columns: stationViewDryStocksColumns,
    },
  });

  return isView ? (
    <div className={classNames(styles.container, styles.viewDryStocksTable)}>
      <DataTableV2 {...table} tableVersion="v2" hideNoSearchResultRender hidePagination />
    </div>
  ) : (
    <div className={classNames(styles.container, styles.plainTable)}>
      <DataTableV2 {...table} hideNoSearchResultRender hidePagination />
    </div>
  );
};

export default ViewDryStocksComponent;
