import { Button, PopOver, Text, Title } from "components/commons";
import styles from "../promo-details.module.scss";
import locale from "localization";
import { useModal, useRouter } from "hooks";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/EditOutlined";
import { SelectStationsModal } from "components/modals";
import { useCallback, useEffect, useMemo, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import useStations from "hooks/useStations";
import { FormMode } from "enums";

const ParticipatingStationsInput = ({ fields, modifyField }) => {
  const { allStations } = useStations();

  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;

  const [selectedStations, setSelectedStations] = useState([]);
  const selectStationsModal = useModal();

  const openSelectionStations = () => {
    selectStationsModal.show({
      title: locale.selectStations,
    });
  };

  const buttonState = useMemo(() => {
    if (selectedStations.length > 0)
      return {
        icon: <EditIcon />,
        text: locale.selectStations,
      };

    return {
      icon: <CheckIcon />,
      text: locale.selectStations,
    };
  }, [selectedStations]);

  const emitStationCodes = useCallback(
    (stationCodes) => {
      setSelectedStations(stationCodes);
      modifyField("participatingStations", {
        value: stationCodes,
      });
    },
    [modifyField]
  );

  const handleOnChange = useCallback(
    (stationCodes) => {
      selectStationsModal.close();
      emitStationCodes(stationCodes);
    },
    [emitStationCodes, selectStationsModal]
  );

  useEffect(() => {
    const values = fields?.participatingStations?.value;
    if (values.length > 0 && pageMode === FormMode.Edit && selectedStations.length === 0) {
      emitStationCodes(values);
    }
  }, [emitStationCodes, fields?.participatingStations?.value, pageMode, selectedStations]);

  return (
    <div>
      <SelectStationsModal
        {...selectStationsModal}
        value={selectedStations}
        onChange={handleOnChange}
        allStations={allStations}
      />
      <Title className={styles.subtitle}>{locale.participatingStations}</Title>
      <div className={styles.selectStationsActions}>
        <Button
          onClick={openSelectionStations}
          startIcon={buttonState.icon}
          variant="outlined"
          className={styles.button}
        >
          {buttonState.text}
        </Button>
        {selectedStations?.length > 0 && allStations?.length > 0 && (
          <>
            <PopOver
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              contentStyle={{
                boxShadow: "10px 0px 20px rgba(0, 0, 0, 0.1)",
              }}
              arrowClassNames={styles.arrow}
              className={styles.popoverContainer}
              content={
                <div className={styles.popOver}>
                  <Text color={"slateblue"} strong>
                    {locale.participating}
                  </Text>
                  {selectedStations?.map((selectedStationCode, i) => {
                    const station = allStations.find(
                      (station) => station?.stationCode === selectedStationCode
                    );
                    return <span key={station?.name + "-" + i}>{station?.name}</span>;
                  })}
                </div>
              }
            >
              <div
                className="link"
                style={{ color: "slateblue", marginLeft: "2.5em", marginTop: "-5px" }}
              >
                <b>
                  {selectedStations?.length} {locale.stations}
                </b>
              </div>
            </PopOver>
            <CloseIcon className={styles.close} onClick={() => handleOnChange([])} />
          </>
        )}
      </div>
    </div>
  );
};

export default ParticipatingStationsInput;
