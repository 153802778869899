import React, { useEffect, useState } from "react";
import styles from "./time-picker.module.scss";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { AccessTimeOutlined } from "@material-ui/icons";
import moment from "moment";
import { isDate, isNumber } from "lodash";
import { Box } from "@material-ui/core";
import Button from "../button/button";
import locale from "localization";
import { combineDateAndTimeInput } from "utils";
import { today12AMtime } from "modules/promos/promo-details/add-edit-promo-form-state";
import { timePickerDefault, timePickerPurple } from "./styles-object";

const TimePickerV2 = ({
  value,
  onChange,
  name,
  minTime,
  date,
  variant = "default",
  actions,
  ...props
}) => {
  const FORMAT = "hh:mm a";

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [textValue, setInputValue] = useState(isDate(value) ? moment(value).format(FORMAT) : "");

  const [error, setError] = useState(false);
  const [pickerValue, setPickerValue] = useState(isDate(value) ? moment(value).toDate() : null);
  const [initialValue, setInitialValue] = useState(0);

  const [isTyping, setIsTyping] = useState(false);

  const timePickerVariant = {
    default: timePickerDefault,
    purple: timePickerPurple,
  };

  useEffect(() => {
    if (initialValue === 0) {
      setInitialValue(value);
    }

    if (!error) {
      setInputValue(isDate(value) ? moment(value).format(FORMAT) : "");
    }

    const stringMinTime = moment(minTime).format("ll hh:mm a");
    const stringTime = moment(value).format("ll hh:mm a");

    if (isTyping || error || stringMinTime === stringTime) return;

    if (isDate(date) && isDate(minTime) && isDate(value) && minTime > value) {
      setPickerValue(minTime);
    }
  }, [minTime, value, date, error, isTyping, initialValue]);

  useEffect(() => {
    onChange?.(name, { value: error ? null : pickerValue, error });
    props?.onError?.(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, pickerValue, error]);

  const handleClick = (event) => {
    if (props?.disabled) return;
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(undefined);
  };

  const handleOnCancel = () => {
    let todayTime = new Date();
    if (props?.defaultValue && isDate(props?.defaultValue)) {
      todayTime = new Date(props?.defaultValue);
    } else {
      todayTime.setHours(0, 0, 0, 0);
    }

    if (minTime && minTime > todayTime) {
      setInputValue(moment(minTime).format(FORMAT));
      setPickerValue(minTime);
    } else {
      setInputValue(moment(todayTime).format(FORMAT));
      setPickerValue(todayTime);
    }

    handleClose();
  };

  function replaceCharAtIndex(originalString, index, newChar) {
    if (index < 0 || index >= originalString.length) {
      return "Index out of bounds";
    }

    return originalString.slice(0, index) + newChar + originalString.slice(index + 1);
  }

  const isBetween = (num, min, max) => {
    return num >= min && num <= max;
  };

  const mutateValue = (inputValue) => {
    if (inputValue.length > 8) {
      return textValue;
    }

    // backspace
    if (textValue.length > inputValue.length) {
      if (inputValue?.[0] === ":") {
        return (inputValue = minTime
          ? moment(minTime).format(FORMAT)
          : moment(today12AMtime()).format(FORMAT));
      }
      return inputValue;
    }

    if (inputValue?.[0]) {
      const isValid1stHour = isNumber(+inputValue?.[0]) && isBetween(+inputValue?.[0], 0, 1);

      if (!isValid1stHour) {
        inputValue = inputValue.slice(0, -1);
      }
    }

    if (inputValue?.[0] && inputValue?.[1]) {
      if (+inputValue?.[0] === 0) {
        const isValid2ndHour = isNumber(+inputValue?.[1]) && isBetween(+inputValue?.[1], 0, 9);

        if (!isValid2ndHour) {
          inputValue = inputValue.slice(0, -1);
        }
      }

      if (+inputValue?.[0] === 1) {
        const isValid2ndHour = isNumber(+inputValue?.[1]) && isBetween(+inputValue?.[1], 0, 2);

        if (!isValid2ndHour) {
          inputValue = inputValue.slice(0, -1);
        }
      }
    }

    if (inputValue?.length === 2) {
      inputValue += ":";
    }

    if (inputValue?.[2]) {
      inputValue = replaceCharAtIndex(inputValue, 2, ":");
    }

    if (inputValue?.[3]) {
      const isValid1stMin = isNumber(+inputValue?.[3]) && isBetween(inputValue?.[3], 0, 5);
      if (!isValid1stMin) {
        inputValue = inputValue.slice(0, -1);
      }
    }

    // hh:mm
    if (inputValue?.[4]) {
      const isValid2ndMin =
        isNumber(+inputValue?.[4]) && +inputValue?.[4] >= 0 && +inputValue?.[4] <= 9;
      if (!isValid2ndMin) {
        inputValue = inputValue.slice(0, -1);
      }
    }

    // hh:mm" "
    if (inputValue?.[5]) {
      inputValue = replaceCharAtIndex(inputValue, 5, " ");
    }

    if (inputValue.length === 5) {
      inputValue += " ";
    }

    // hh:mm am/pm
    if (inputValue.length >= 8 && !["am", "pm"].includes(inputValue.slice(-2).toLowerCase())) {
      inputValue = inputValue.slice(0, -2);
    }

    return inputValue;
  };

  const handleInputChange = (event) => {
    if (props?.disabled) return;
    let inputValue = event.target.value;

    inputValue = mutateValue(inputValue);
    setInputValue(inputValue);

    const timeFormat = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i; // Enforce exactly one space
    if (timeFormat.test(inputValue)) {
      const [time, period] = inputValue.trim().split(" ");
      const [hours, minutes] = time.split(":").map(Number);

      let formattedHours = hours;
      if (period?.toUpperCase() === "PM" && hours !== 12) {
        formattedHours += 12; // Convert PM to 24-hour format
      }
      if (period?.toUpperCase() === "AM" && hours === 12) {
        formattedHours = 0; // Handle midnight case
      }

      const newDate = new Date();
      newDate.setHours(formattedHours);
      newDate.setMinutes(minutes);
      newDate.setSeconds(0);

      // const displayedOnClock = combineDateAndTimeInput(date, newDate);
      const newValue = combineDateAndTimeInput(date, newDate);

      if (minTime && minTime > newValue) {
        setInputValue(moment(minTime).format(FORMAT));
        setPickerValue(minTime);
      } else {
        setPickerValue(newValue);
      }

      setError(false);
    } else {
      setError(true);
    }
  };

  const handlePickerChange = (newValue) => {
    setPickerValue(newValue);
    setInputValue(moment(newValue).format(FORMAT));
    setError(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TextField
        onChange={handleInputChange}
        value={textValue}
        InputProps={{
          endAdornment: <AccessTimeOutlined onClick={handleClick} color="disabled" />,
          value: textValue,
          onFocus: () => setIsTyping(true),
          onBlur: () => {
            if (error) {
              if (minTime) {
                setInputValue(moment(minTime).format(FORMAT));
                setPickerValue(minTime);
                setError(false);
              } else {
                if (initialValue !== 0) {
                  setInputValue(moment(initialValue).format(FORMAT));
                  setPickerValue(initialValue);
                  setError(false);
                }
              }
            }
            setIsTyping(false);
          },
        }}
        error={error}
        className={styles.input}
        placeholder={props?.placeholder}
        style={{ opacity: props?.disabled ? 0.5 : 1 }}
      />
      <Popover
        id={open ? "pop-over-content" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <StaticTimePicker
          sx={timePickerVariant[variant]}
          componentsProps={{ actionBar: { actions: [] } }}
          {...props}
          value={pickerValue || minTime || new Date()}
          minTime={minTime}
          onChange={handlePickerChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <Box display={"flex"} justifyContent={"space-between"} margin={"10px 5px"}>
          <Box>
            {actions?.clear && (
              <Button variant="text" className={styles.buttonSky} onClick={handleOnCancel}>
                <b>{locale.clear}</b>
              </Button>
            )}
          </Box>
          <Box display={"flex"} justifyContent={"end"}>
            <Box>
              {actions?.cancel && (
                <Button variant="text" className={styles.buttonSky} onClick={handleOnCancel}>
                  <b>{locale.cancel}</b>
                </Button>
              )}
            </Box>
            <Box>
              {actions?.ok && (
                <Button
                  variant="text"
                  onClick={() => {
                    const displayedOnClock = pickerValue || minTime || new Date();
                    if (minTime && minTime > displayedOnClock) {
                      setInputValue(moment(minTime).format(FORMAT));
                      setPickerValue(minTime);
                    } else {
                      setInputValue(moment(displayedOnClock).format(FORMAT));
                      setPickerValue(displayedOnClock);
                    }

                    handleClose();
                  }}
                  className={styles.buttonSky}
                >
                  <b>{locale.ok}</b>
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
};

export default TimePickerV2;
