import React from "react";

import styles from "./promos-list-filter.module.scss";

import { Field, Filter, SearchField, Autocomplete, Button } from "components/commons";
import { prettifyPromoStatus, prettifyPromoType } from "utils/pretty.utils";
import locale from "localization";
import { PromoStatusEnum, PromoTypeEnum } from "enums";
import DateRange from "components/commons/date-range/date-range";

const PromosListFilter = ({
  filterState,
  modifyFilter,
  filterCount,
  applyFilter,
  applyClearFilter,
  searchKey,
  modifySearch,
  applySearch,
  applyClearSearch,
  resetFilter,
  exporting,
  exportReport,
}) => {
  return (
    <div className={styles?.container}>
      <div className={styles?.filter}>
        <Filter
          close={resetFilter}
          clear={applyClearFilter}
          submit={() => {
            applyFilter();
          }}
          filterCount={filterCount}
        >
          <Field
            labelPosition={"left"}
            childrenClassName={styles.fieldContent}
            label={locale.promoDate}
          >
            <DateRange
              className={styles.date}
              placeholder={locale.selectDates}
              value={{
                startDate: filterState?.startDate,
                endDate: filterState?.endDate,
              }}
              onChange={(_, { value }) => {
                const { startDate, endDate } = value;
                modifyFilter({
                  startDate: startDate,
                  endDate: endDate,
                });
              }}
            />
          </Field>

          <Field
            label={locale.promoType}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"promoType"}
              // hasAll
              multiple
              options={[
                // {
                //   label: prettifyPromoType(PromoTypeEnum.DiscountPerLiter),
                //   value: PromoTypeEnum.DiscountPerLiter,
                // },
                // {
                //   label: prettifyPromoType(PromoTypeEnum.DiscountPerTransaction),
                //   value: PromoTypeEnum.DiscountPerTransaction,
                // },
                {
                  label: prettifyPromoType(PromoTypeEnum.DiscountPerUnit),
                  value: PromoTypeEnum.DiscountPerUnit,
                },
                // {
                //   label: prettifyPromoType(PromoTypeEnum.PercentDiscount),
                //   value: PromoTypeEnum.PercentDiscount,
                // },
              ]}
              {...filterState.promoType}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
          <Field
            label={locale.status}
            labelPosition={"left"}
            className={styles.fieldSelect}
            childrenClassName={styles.fieldContent}
          >
            <Autocomplete
              name={"status"}
              hasAll
              multiple
              options={[
                {
                  label: prettifyPromoStatus(PromoStatusEnum.Upcoming),
                  value: PromoStatusEnum.Upcoming,
                },
                {
                  label: prettifyPromoStatus(PromoStatusEnum.Ongoing),
                  value: PromoStatusEnum.Ongoing,
                },
                {
                  label: prettifyPromoStatus(PromoStatusEnum.Cancelled),
                  value: PromoStatusEnum.Cancelled,
                },
                {
                  label: prettifyPromoStatus(PromoStatusEnum.Ended),
                  value: PromoStatusEnum.Ended,
                },
              ]}
              {...filterState.status}
              onChange={(name, { value, isSelectedAll }) =>
                modifyFilter({ [name]: { value, isSelectedAll } })
              }
            />
          </Field>
        </Filter>
        <Field className={styles.search}>
          <SearchField
            restrict={false}
            searchWithSymbols
            placeholder={locale.promoName}
            value={searchKey}
            onChange={(_, { value }) => {
              modifySearch(value);
            }}
            onEnter={applySearch}
            onClear={applyClearSearch}
          />
        </Field>
        {exportReport && exporting && (
          <>
            <Field className={styles.downloadButton}>
              <Button
                startIcon={<span className="icon-download" />}
                onClick={exportReport}
                exportButton
                loading={exporting}
              >
                {locale.export}
              </Button>
            </Field>
          </>
        )}
      </div>
    </div>
  );
};

export default PromosListFilter;
