import { PromoTypeEnum } from "enums";

export const initialFilterState = () => {
  return {
    searchKey: "",
    page: 1,
    perPage: 10,
    promoType: {
      isSelectedAll: true,
      value: [PromoTypeEnum.DiscountPerUnit],
    },
  };
};
