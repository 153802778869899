const EmployeePositionEnum = {
  STATION_HEAD: "station-head",
  STATION_CREW: "station-crew",
  CASHIER: "cashier",
  SECURITY_GUARD: "security-guard",
  SERVICE_ADVISOR: "service-advisor",
  TECHNICIAN: "technician",
  CREW: "employee",
};

export default EmployeePositionEnum;
