import { Pill, PopOver, PopOverMenu, Product, Text } from "components/commons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { locale } from "localization/en";
import { formatAmount } from "utils/text.utils";
import styles from "./promos-list.module.scss";
import { dateTimeFormat } from "utils";
import {
  prettifyCancelPromoReason,
  prettifyProductCode,
  prettifyPromoStatus,
  prettifyPromoType,
} from "utils/pretty.utils";
import {
  FormMode,
  ProductCodeEnum,
  PromoCancelReasonEnum,
  PromoStatusEnum,
  PromoTypeEnum,
} from "enums";
import { PromoTypeToApiEnum } from "enums/promo-type.enum";
import { sortProductsBySku } from "./promo-details/add-edit-components/product-input";
import moment from "moment";

export const mapDataToList = ({ promos = {}, products, handleOnClickActionOption }) => {
  const {
    promoDate,
    promoName,
    promoType,
    product,
    transactionCap,
    participatingStations,
    createdBy,
    updatedAt,
    createdAt,
    reason,
    remarks,
    status,
  } = mapApiResponseToDataTable(promos);

  const textProps = {
    className: styles.textStyle,
    color: "#34384b",
  };

  const options = [
    {
      removable: true,
      content: locale.viewDetails,
      onClick: () => {
        handleOnClickActionOption?.(promos, FormMode.View);
      },
    },
  ];

  if (status === PromoStatusEnum.Upcoming || status === PromoStatusEnum.Ongoing) {
    const indexToPush = 1;
    const editOption = {
      removable: true,
      content: locale.editDetails,
      onClick: () => {
        handleOnClickActionOption?.(promos, FormMode.Edit);
      },
    };
    options.splice(indexToPush, 0, editOption);
    options.push({
      removable: true,
      content: locale.cancelPromo,
      onClick: () => {
        handleOnClickActionOption?.(promos, FormMode.Cancel);
      },
    });
  }

  const renderProduct = () => {
    const products = product.map(({ sku, name, variant, value }, i) => ({
      product: Object.values(ProductCodeEnum).includes(sku) ? (
        <Product
          key={`${sku}-${i}`}
          grass={sku === ProductCodeEnum.GAS91}
          salsa={sku === ProductCodeEnum.GAS95}
          deepBlue={sku === ProductCodeEnum.GAS97}
          cheddar={sku === ProductCodeEnum.DIESEL}
          // purple={!Object.values(ProductCodeEnum).includes(sku)}
        >
          <Text {...textProps}>{name || prettifyProductCode(sku)}</Text>
        </Product>
      ) : (
        <Text {...textProps}>{name || prettifyProductCode(sku)}</Text>
      ),
      variant: <Text {...textProps}>{variant && variant !== "NULL" ? variant : "--"}</Text>,
      value: (
        <Text key={`${value}-${i}`} {...textProps}>
          {promoType === PromoTypeEnum.PercentDiscount
            ? `${value}%`
            : formatAmount(value || 0, "₱")}
        </Text>
      ),
    }));

    return (
      <PopOver
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        contentStyle={{
          boxShadow: "10px 0px 20px rgba(0, 0, 0, 0.1)",
          overflowY: "scroll",
          width: "480px",
          padding: "5px 0px",
          margin: "10px",
          height: "179px",
        }}
        className={styles.popoverContainer}
        arrowClassNames={styles.arrow}
        content={
          <table style={{ width: "100%", borderCollapse: "separate", borderSpacing: "10px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", width: "50%" }}>
                  <Text
                    strong
                    {...textProps}
                    style={{
                      paddingRight: "1em",
                    }}
                  >
                    {locale.product}
                  </Text>
                </th>
                <th style={{ textAlign: "left" }}>
                  <Text strong {...textProps}>
                    {locale.productVariant}
                  </Text>
                </th>
                <th style={{ textAlign: "left" }}>
                  <Text strong {...textProps}>
                    {locale.promoAmount}
                  </Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map(({ product, variant, value }) => (
                <tr>
                  <td
                    style={{
                      paddingRight: "1em",
                    }}
                  >
                    <div>{product}</div>
                  </td>
                  <td>
                    <div>{variant}</div>
                  </td>
                  <td>
                    <div>{value}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      >
        <div className="link" style={{ color: "slateblue" }}>
          <b>{locale.view}</b>
        </div>
      </PopOver>
    );
  };

  const renderParticipatingStations = () => {
    const stations = participatingStations?.map((station) => station.name);
    if (stations?.length === 0) return "--";

    if (stations?.length === 1) {
      return stations[0];
    }

    return (
      <div className={styles.popOver}>
        {stations[0]}
        <PopOver
          content={
            <div className={styles.popOver}>
              <Text strong {...textProps}>
                {locale.participating}
              </Text>
              {stations.map((station, i) => (
                <Text key={`${station}-${i}`} {...textProps}>
                  {station}
                </Text>
              ))}
            </div>
          }
        >
          <div className="link" style={{ color: "slateblue" }}>
            <b>{locale.view}</b>
          </div>
        </PopOver>
      </div>
    );
  };

  const renderStatus = () => {
    if (!status) return "--";

    const edited = createdAt !== updatedAt;

    let statusDate = {
      [PromoStatusEnum.Upcoming]: edited ? updatedAt : createdAt,
      [PromoStatusEnum.Ongoing]: edited
        ? updatedAt
        : moment(createdAt).toDate() > moment(promoDate.startDate).toDate()
        ? createdAt
        : promoDate.startDate,
      [PromoStatusEnum.Ended]: promoDate.endDate,
      [PromoStatusEnum.Cancelled]: updatedAt,
    };

    return (
      <div>
        <Pill
          sky={status === PromoStatusEnum.Ongoing}
          cheddar={status === PromoStatusEnum.Upcoming}
          cement={[PromoStatusEnum.Ended, PromoStatusEnum.Cancelled].includes(status)}
        >
          {prettifyPromoStatus(status)}
        </Pill>
        <Text
          style={{
            marginTop: "5px",
          }}
          subtitle
        >
          {dateTimeFormat(statusDate[status])}
        </Text>
      </div>
    );
  };

  const renderCreatedBy = () => {
    return (
      <div>
        <Text style={{ width: "130px", wordBreak: "break-all" }} {...textProps}>
          {createdBy || "--"}
        </Text>
        <Text subtitle style={{ width: "130px", wordBreak: "break-all" }}>
          {dateTimeFormat(createdAt)}
        </Text>
      </div>
    );
  };

  const renderReasonRemarks = () => {
    if (status !== PromoStatusEnum.Cancelled) return;

    const getCancelledByValue = () => {
      const match = remarks?.match(/Cancelled by:\s*([^\s]+)/);
      return match ? match[1] : null;
    };
    const getRemarks = () => {
      const match = remarks?.split("Cancelled by:")[0].replace(";", "").trim();
      return match ? match : null;
    };

    return (
      <PopOver
        style={{
          wordBreak: "break-all",
        }}
        content={
          <div className={styles.popOver}>
            <div className={styles.reasonRemarksItem}>
              <Text color={"#9bafd7"}>{locale.cancelledBy}</Text>
              <Text color="#000" className={styles.textNight}>
                {getCancelledByValue() || "--"}
              </Text>
            </div>

            <div className={styles.reasonRemarksItem}>
              <Text color={"#9bafd7"}>{locale.reason}</Text>
              <Text color="#000" className={styles.textNight}>
                {prettifyCancelPromoReason(reason) || "--"}
              </Text>
            </div>

            {reason === PromoCancelReasonEnum.OTHERS && (
              <div className={styles.reasonRemarksItem}>
                <Text color={"#9bafd7"}>{locale.remarks}</Text>
                <Text color="#000" className={styles.textNight}>
                  {getRemarks() || "--"}
                </Text>
              </div>
            )}
          </div>
        }
      >
        <div className="link" style={{ color: "slateblue" }}>
          <center>
            <b>View</b>
          </center>
        </div>
      </PopOver>
    );
  };

  return {
    promoDate: (
      <div style={{ width: "150px" }} {...textProps}>
        {dateTimeFormat(promoDate?.startDate) || "--"} -{" "}
        {dateTimeFormat(promoDate?.endDate) || "--"}
      </div>
    ),
    promoName: (
      <div style={{ width: "150px", wordBreak: "break-all" }} {...textProps}>
        {promoName || "--"}
      </div>
    ),
    promoType: (
      <div
        style={{ width: promoType === PromoTypeEnum.DiscountPerUnit ? "70px" : "100px" }}
        {...textProps}
      >
        {promoType ? prettifyPromoType(promoType) : "--"}
      </div>
    ),
    product: <div style={{ width: "100px" }}>{renderProduct() || "--"}</div>,
    transactionCap: (
      <div style={{ width: "100px" }} {...textProps}>
        {transactionCap > 0 ? formatAmount(transactionCap || 0) : locale.noLimit}
      </div>
    ),
    participatingStations: (
      <div style={{ width: "212px" }} {...textProps}>
        {renderParticipatingStations() || "--"}
      </div>
    ),
    createdBy: <div>{renderCreatedBy() || "--"}</div>,
    status: <div>{renderStatus()}</div>,
    reasonRemarks: <div>{renderReasonRemarks()}</div>,
    actions: options?.length > 0 && (
      <PopOverMenu options={options}>
        <MoreVertIcon className={styles.icon} />
      </PopOverMenu>
    ),
  };
};

export const mapFilterToRequest = ({ promoType, status, ...fs }) => {
  if (promoType.value.length > 0) {
    fs.discountType = promoType.value.map((value) => PromoTypeToApiEnum[value]).join(",");
  }

  if (promoType.isSelectedAll) {
    fs.discountType = undefined;
  }

  if (status.value.length > 0) {
    fs.status = status.value.join(",");
  }

  if (status.isSelectedAll) {
    fs.status = undefined;
  }

  return fs;
};

export const mapApiResponseToDataTable = (responseData) => {
  const promoDetailsSortBySku = sortProductsBySku(responseData?.promoDetails, "name");

  return {
    promoId: responseData?.promoId,
    promoDate: {
      startDate: responseData?.startDate,
      endDate: responseData?.endDate,
    },
    promoName: responseData?.name,
    promoType: PromoTypeToApiEnum[responseData?.discountType],
    transactionCap: responseData?.transactionCap,
    product: promoDetailsSortBySku,
    promoAmount: promoDetailsSortBySku?.map((product) => ({
      sku: product?.sku,
      value: product?.value,
    })),
    participatingStations: responseData?.promoStations || [],
    status: responseData?.status,
    createdBy: responseData?.createdBy,
    createdAt: responseData?.createdAt,
    updatedAt: responseData?.updatedAt,
    reason: responseData?.reason,
    remarks: responseData?.remarks,
    cancelledBy: responseData?.cancelledBy,
    transactionDiscount: responseData?.transactionDiscount,
    minPurchase: responseData?.minPurchase,
  };
};
