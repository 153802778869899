// import { useCallback, useEffect, useState } from "react";
// import ConfirmModal from "../confirm-modal/confirm-modal";
// import SelectPromosFilter from "./select-promo-filter";
// import useDataTable from "hooks/useDataTable";
// import { initialFilterState } from "./select-promo-filter-state";
// import { promosColumn } from "./select-promo-columns";
// import { mapDataToList, mapFilterToRequest } from "./select-promo-mapper";
// import { getPromoListApi } from "apis";
// import DataTableV2 from "components/commons/data-table/data-table-v2";
// import locale from "localization";
// import { useApi } from "hooks";
// import styles from "./select-promo-modal.module.scss";
// import { handleRequest } from "utils";
// import classNames from "classnames";
// import { Loader } from "components/commons";
// import { PromoStatusEnum } from "enums";

// const SelectPromosModal = (
//   modal = {
//     onFetch: (allPromos) => 0,
//     allPromos: [],
//     excludeStatus: [],
//   }
// ) => {
//   const [allPromos, setAllPromos] = useState([]);
//   const [isCheckAll, setIsCheckAll] = useState(false);
//   const [selected, setSelected] = useState(modal?.value || []);

//   const promoSearchFilter = [locale.sku, locale.promoName].join(", ");
//   const stationSearchFilter = locale.promoName;

//   const onCheck = (id) => {
//     if (selected.includes(id)) {
//       setSelected((s) => s.filter((selectedPromo) => selectedPromo !== id));
//     } else {
//       setSelected((s) => [...s, id]);
//     }
//   };

//   const handleOnCheckAll = () => {
//     setIsCheckAll(!isCheckAll);
//     setSelected((prevSelected) => {
//       const filteredResult = allPromos?.map(({ promoId }) => promoId);
//       let result = [];
//       if (isCheckAll) {
//         result = prevSelected.filter((promoId) => !filteredResult.includes(promoId));
//       } else {
//         result = [...new Set([...prevSelected, ...filteredResult])];
//       }

//       return result;
//     });
//   };

//   const onClickSave = () => {
//     modal?.onChange?.(
//       selected
//         .map((id) => modal?.allPromos.find(({ promoId }) => promoId === id))
//         .filter((promo) => !!promo)
//     );
//   };

//   const { filter, search, table } = useDataTable({
//     api: {
//       api: getPromoListApi,
//       mapper: (param) => param,
//     },
//     filter: {
//       initialState: {
//         ...initialFilterState(),
//         status: {
//           isSelectedAll: !modal?.excludeStatus || modal?.excludeStatus?.length === 0,
//           value: Object.values(PromoStatusEnum).filter(
//             (value) => !modal?.excludeStatus?.includes(value)
//           ),
//         },
//       },
//       mapper: mapFilterToRequest,
//     },
//     table: {
//       key: "promos",
//       mapper: (promo) =>
//         mapDataToList({
//           promo,
//           onCheck,
//           selected,
//         }),
//       columns: promosColumn,
//     },
//   });

//   const getAllPromos = useApi({
//     api: getPromoListApi,
//   });

//   const fetchAllPromos = useCallback(
//     async (filterState) => {
//       const res = await getAllPromos?.request({
//         ...mapFilterToRequest({
//           ...filterState,
//           status: {
//             isSelectedAll: !modal?.excludeStatus || modal?.excludeStatus?.length === 0,
//             value: Object.values(PromoStatusEnum).filter(
//               (value) => !modal?.excludeStatus?.includes(value)
//             ),
//           },
//           perPage: 1000,
//         }),
//       });

//       const promos = res.promos;
//       if (isCheckAll) {
//         setSelected(res.promos?.map((promo) => promo.promoId));
//       }

//       return promos;
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     [modal?.excludeStatus, isCheckAll]
//   );

//   useEffect(() => {
//     handleRequest(async () => {
//       search?.applyClearSearch();
//       if (modal?.allPromos && modal?.allPromos?.length > 0) {
//         setAllPromos(modal?.allPromos || []);
//       } else {
//         const allPromosRes = await fetchAllPromos(initialFilterState());
//         setAllPromos(allPromosRes || []);
//         modal?.onFetch?.(allPromosRes);
//       }
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.allPromos]);

//   useEffect(() => {
//     if (modal?.active && modal?.value && modal?.value?.length >= 0) {
//       setAllPromos(modal?.allPromos || allPromos);
//       setSelected(modal?.value);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.value, modal?.active, modal?.allPromos]);

//   useEffect(() => {
//     if (modal?.active) {
//       setIsCheckAll(false);
//       search?.modifySearch("");
//       filter.applyClearFilter();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.active]);

//   const applyClearSearch = async () => {
//     setAllPromos(
//       await fetchAllPromos({
//         ...filter.filterState,
//         searchKey: undefined,
//         perPage: 1000,
//         id: "all",
//       })
//     );
//     search.applyClearSearch();
//   };

//   const applyFilter = async () => {
//     setAllPromos(
//       fetchAllPromos({
//         ...filter.filterState,
//         searchKey: "",
//         perPage: 1000,
//         id: "all",
//       })
//     );
//     filter?.applyFilter();
//   };

//   const applySearch = async () => {
//     setAllPromos(
//       await fetchAllPromos({
//         ...filter.filterState,
//         searchKey: search.searchKey,
//         perPage: 1000,
//         id: "all",
//       })
//     );

//     search.applySearch();
//   };

//   const applyClearFilter = async () => {
//     setAllPromos(
//       await fetchAllPromos({
//         ...initialFilterState(),
//         searchKey: "",
//         perPage: 1000,
//         id: "all",
//       })
//     );
//     search.modifySearch("");
//     filter.applyClearFilter();
//   };

//   return (
//     <ConfirmModal
//       {...modal}
//       className={classNames(styles.modal, {
//         [`${styles.station}`]: modal?.fromStation,
//         [`${styles.promos}`]: !modal?.fromStation,
//       })}
//       primary={{
//         ...modal?.primary,
//         text: modal?.primary?.text || locale.save,
//         disabled: selected.length === 0,
//         onClick: onClickSave,
//       }}
//       secondary={{ ...modal?.secondary, text: locale.cancel }}
//     >
//       <SelectPromosFilter
//         {...filter}
//         {...search}
//         applyClearSearch={applyClearSearch}
//         applySearch={applySearch}
//         applyFilter={applyFilter}
//         applyClearFilter={applyClearFilter}
//         actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
//         actionProps={{
//           link: selected.length > 0,
//           variant: "text",
//           style: {
//             opacity: selected.length === 0 && 0.5,
//           },
//         }}
//         actionOnClick={() => {
//           setSelected([]);
//           setIsCheckAll(false);
//         }}
//         // uniquePromoTypes={uniquePromoTypes}
//         placeholder={modal?.fromStation ? stationSearchFilter : promoSearchFilter}
//       />
//       {getAllPromos.loading ? (
//         <Loader open />
//       ) : (
//         <DataTableV2
//           {...table}
//           onCheckAll={handleOnCheckAll}
//           actionHeaderProps={{
//             checked: isCheckAll,
//           }}
//           size="small"
//         />
//       )}
//     </ConfirmModal>
//   );
// };

// export default SelectPromosModal;

import ConfirmModal from "../confirm-modal/confirm-modal";
import SelectPromosFilter from "./select-promo-filter";
import { initialFilterState } from "./select-promo-filter-state";
import { promosColumn } from "./select-promo-columns";
import { mapDataToList, mapFilterToRequest } from "./select-promo-mapper";
import { getPromoListApi } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import styles from "./select-promo-modal.module.scss";
import { excludeFilterAutoComplete } from "utils";
import classNames from "classnames";
import { Loader } from "components/commons";
import { PromoStatusEnum } from "enums";
import useSelectableItemsModal from "hooks/useSelectableItems";

const SelectPromosModal = ({
  onFetch = (allPromos) => 0,
  allPromos = [],
  excludeStatus = [],
  value,
  coloredProduct,
  fromStation,
  onChange,
  ...modal
}) => {
  const dataTable = {
    api: {
      api: getPromoListApi,
      mapper: (param) => param,
    },
    filter: {
      initialState: {
        ...initialFilterState(),
        status: {
          isSelectedAll: !excludeStatus || excludeStatus?.length === 0,
          value: Object.values(PromoStatusEnum).filter((value) => !excludeStatus?.includes(value)),
        },
      },
      mapper: mapFilterToRequest,
    },
    table: {
      key: "promos",
      mapper: (promo, { onCheck, selected }) =>
        mapDataToList({
          promo,
          onCheck,
          selected,
        }),
      columns: promosColumn,
    },
  };

  const {
    selected,
    setSelected,
    handleOnCheckAll,
    setIsCheckAllState,
    isCheckAllState,
    getAllItems,
    dataTable: { table, filter, search },
  } = useSelectableItemsModal({
    active: modal?.active,
    uniqueKey: "promoId",
    allItems: allPromos,
    value: value,
    dataTable,
    onFetchAllItemMapFilterState: (filterState) => {
      return {
        ...filterState,
        status: excludeFilterAutoComplete({
          ...filterState.status,
          exclude: excludeStatus,
          allOptions: Object.values(PromoStatusEnum),
        }),
      };
    },
  });

  const promoSearchFilter = [locale.sku, locale.promoName].join(", ");
  const stationSearchFilter = locale.promoName;

  const onClickSave = () => {
    onChange?.(
      selected
        .map((id) => allPromos.find(({ promoId }) => promoId === id))
        .filter((promo) => !!promo)
    );
  };

  return (
    <ConfirmModal
      {...modal}
      className={classNames(styles.modal, {
        [`${styles.station}`]: fromStation,
        [`${styles.promos}`]: !fromStation,
      })}
      primary={{
        ...modal?.primary,
        text: modal?.primary?.text || locale.save,
        disabled: selected.length === 0,
        onClick: onClickSave,
      }}
      secondary={{ ...modal?.secondary, text: locale.cancel }}
    >
      <SelectPromosFilter
        {...filter}
        {...search}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: "text",
          style: {
            opacity: selected.length === 0 && 0.5,
          },
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAllState(false);
        }}
        // uniquePromoTypes={uniquePromoTypes}
        placeholder={fromStation ? stationSearchFilter : promoSearchFilter}
      />
      {getAllItems.loading ? (
        <Loader open />
      ) : (
        <DataTableV2
          {...table}
          onCheckAll={handleOnCheckAll}
          actionHeaderProps={{
            checked: isCheckAllState,
          }}
          size="small"
        />
      )}
    </ConfirmModal>
  );
};

export default SelectPromosModal;
