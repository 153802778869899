import moment from "moment";
import Validation from "utils/validation.utils";
import locale from "localization";
import { roundUp } from "utils";
import { isNumber } from "lodash";
import { PromoTypeEnum } from "enums";
const initialState = (promoDetails, isEdit) => {
  const { endTime, startTime } = modifyTimeRangeValue({
    endDate: promoDetails?.promoDate?.endDate,
    startDate: promoDetails?.promoDate?.startDate,
  });

  const form = {
    promoDate: {
      name: "promoDate",
      value: {
        startDate: promoDetails?.promoDate?.startDate
          ? moment(promoDetails?.promoDate?.startDate).toDate()
          : null,
        endDate: promoDetails?.promoDate?.endDate
          ? moment(promoDetails?.promoDate?.endDate).toDate()
          : null,
      },
      horizontal: true,
      type: "date",
      required: true,
      minDate: new Date(),
      validations: [Validation.dateRangeRequired],
    },
    promoName: {
      name: "promoName",
      value: promoDetails?.promoName || "",
      required: true,
      placeholder: locale.typeHere,
      validations: [Validation.maxlength(150), Validation.required()],
    },
    promoType: {
      name: "promoType",
      value: PromoTypeEnum.DiscountPerUnit,
      required: true,
      placeholder: locale.selectPromoType,
      validations: [Validation.required()],
      disabled: true,
    },
    transactionCap: {
      name: "transactionCap",
      value: isNumber(promoDetails?.transactionCap)
        ? roundUp(promoDetails?.transactionCap, "0.00")
        : "",
      placeholder: "0.00",
      required: true,
      innerLabel: true,
      maxLength: 9,
      validations: [
        Validation.maxlength(11),
        Validation.required(),
        Validation.min(0, { isFormatted: true }),
      ],
      hidden: promoDetails?.promoType === PromoTypeEnum.DiscountPerTransaction,
    },
    transactionDiscount: {
      name: "transactionDiscount",
      value: isNumber(promoDetails?.transactionDiscount)
        ? roundUp(promoDetails?.transactionDiscount, "0.00")
        : "",
      placeholder: "0.00",
      required: true,
      innerLabel: true,
      maxLength: 9,
      isPriceValue: true,
      max: 999999.99,
      min: 0.01,
      validations: [
        Validation.maxlength(11),
        Validation.required(),
        Validation.min(0, { isFormatted: true }),
      ],
      hidden: promoDetails?.promoType === PromoTypeEnum.DiscountPerUnit,
    },
    minPurchase: {
      name: "minPurchase",
      value: isNumber(promoDetails?.minPurchase) ? roundUp(promoDetails?.minPurchase, "0.00") : "",
      placeholder: "0.00",
      required: true,
      innerLabel: true,
      maxLength: 9,
      isPriceValue: true,
      max: 999999.99,
      min: 0.01,
      validations: [
        Validation.maxlength(11),
        Validation.required(),
        Validation.min(0, { isFormatted: true }),
      ],
      hidden:
        promoDetails?.promoType === PromoTypeEnum.PercentDiscount ||
        promoDetails?.promoType === PromoTypeEnum.DiscountPerUnit,
    },
    startTime: {
      name: "startTime",
      required: true,
      value: startTime,
      validations: [Validation.required()],
      placeholder: "hh:mm am/pm",
      // helperText: moment(promoDetails?.promoDate?.startDate).format("hh:mm a"),
    },
    endTime: {
      name: "endTime",
      required: true,
      value: endTime,
      validations: [Validation.required()],
      placeholder: "hh:mm am/pm",
      // helperText: moment(promoDetails?.promoDate?.endDate).format("hh:mm a"),
    },
    product: {
      name: "product",
      value: isEdit
        ? promoDetails?.product.map(({ sku, value, name, variant }) => ({
            sku,
            value: roundUp(value, "0.00"),
            label: name,
            product: sku,
            variant,
          }))
        : [],
      required: true,
      error: promoDetails?.product?.length === 0,
      validations: [Validation.requiredArray({ notEmpty: true })],
      hidden: promoDetails?.promoType !== PromoTypeEnum.DiscountPerUnit,
    },
    participatingStations: {
      name: "participatingStations",
      value: promoDetails?.participatingStations?.map(({ stationCode }) => stationCode) || [],
      isMultiple: true,
      disableAllOption: true,
      error: promoDetails?.participatingStations?.length === 0,
      allLabel: "Station",
      allSelectedText: "All Station",
      selectAllLabel: "All Station",
      validations: [Validation.requiredArray({ notEmpty: true })],
    },
  };

  return form;
};

export const today12AMtime = () => {
  let todayTime = new Date();
  todayTime.setHours(0, 0, 0, 0);

  return todayTime;
};

export const today1159PMTime = () => {
  let todayTime = new Date();
  todayTime.setHours(23, 59, 59, 999);

  return todayTime;
};

export const modifyTimeRangeValue = ({ startDate, endDate }) => {
  let startTime = startDate ? moment(startDate).toDate() : null;
  let endTime = endDate ? moment(endDate).toDate() : null;

  if (!endTime) {
    startTime = today12AMtime();
    endTime = moment(today12AMtime()).add(12, "hours").toDate();
  }

  return {
    startTime,
    endTime,
  };
};

export default initialState;
