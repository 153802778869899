// import { useCallback, useEffect, useMemo, useState } from "react";
// import ConfirmModal from "../confirm-modal/confirm-modal";
// import SelectProductsFilter from "./select-products-filter";
// import useDataTable from "hooks/useDataTable";
// import { initialFilterState } from "./select-products-filter-state";
// import { productsColumn, stationProductsColumn } from "./select-products-columns";
// import { mapDataToList, mapFilterToRequest } from "./select-products-mapper";
// import { getProductsApi } from "apis";
// import DataTableV2 from "components/commons/data-table/data-table-v2";
// import locale from "localization";
// import { useApi } from "hooks";
// import styles from "./select-products-modal.module.scss";
// import { handleRequest } from "utils";
// import { prettifyProductType } from "utils/pretty.utils";
// import classNames from "classnames";

// const SelectProductsModal = (
//   modal = {
//     onFetch: (allProducts) => 0,
//     allProducts: [],
//     fromStation: false,
//     coloredProduct: true,
//     excludeProductType: [],
//   }
// ) => {
//   const [allProducts, setAllProducts] = useState([]);
//   const [isCheckAll, setIsCheckAll] = useState(false);
//   const [selected, setSelected] = useState(modal?.value || []);

//   const promoSearchFilter = [locale.sku, locale.productName].join(", ");
//   const stationSearchFilter = locale.productName;

//   const onCheck = (sku) => {
//     if (selected.includes(sku)) {
//       setSelected((s) => s.filter((selectedSku) => selectedSku !== sku));
//     } else {
//       setSelected((s) => [...s, sku]);
//     }
//   };

//   const handleOnCheckAll = () => {
//     setIsCheckAll(!isCheckAll);
//     setSelected((prevSelected) => {
//       const filteredResult = allProducts?.map(({ sku }) => sku);
//       let result = [];
//       if (isCheckAll) {
//         result = prevSelected.filter((sku) => !filteredResult.includes(sku));
//       } else {
//         result = [...new Set([...prevSelected, ...filteredResult])];
//       }
//       return result;
//     });
//   };

//   const onClickSave = () => {
//     modal?.onChange?.(
//       selected
//         .map((name) => modal?.allProducts.find(({ sku }) => sku === name))
//         .filter((product) => !!product)
//     );
//   };

//   const uniqueProductTypes = useMemo(() => {
//     const all = modal?.allProducts || allProducts;
//     const options = all.reduce((acc, { category, type }) => {
//       if (!acc.some((p) => p.value === type)) {
//         acc.push({ label: prettifyProductType(type), value: type });
//       }
//       return acc;
//     }, []);

//     return options.filter(({ value }) => !modal?.excludeProductType?.includes(value));
//   }, [allProducts, modal.allProducts, modal?.excludeProductType]);

//   const { filter, search, table } = useDataTable({
//     api: {
//       api: getProductsApi,
//       mapper: (param) => param,
//     },
//     filter: {
//       initialState: {
//         ...initialFilterState(),
//         productType: {
//           isSelectedAll: !modal?.excludeProductType || modal?.excludeProductType?.length === 0,
//           value: uniqueProductTypes.map(({ value }) => value),
//         },
//       },
//       mapper: mapFilterToRequest,
//     },
//     table: {
//       key: "products",
//       mapper: (product) =>
//         mapDataToList({
//           product,
//           onCheck,
//           selected,
//           coloredProduct: modal?.coloredProduct,
//         }),
//       columns: modal?.fromStation ? stationProductsColumn : productsColumn,
//     },
//   });

//   const getAllProducts = useApi({
//     api: getProductsApi,
//   });

//   const fetchAllProducts = useCallback(
//     async (filterState) => {
//       const res = await getAllProducts?.request({
//         ...mapFilterToRequest({
//           ...filterState,
//           productType: {
//             isSelectedAll:
//               modal?.excludeProductType?.length > 0
//                 ? false
//                 : filterState?.productType?.isSelectedAll,
//             value:
//               filterState.productType?.value.length === 0
//                 ? uniqueProductTypes.map(({ value }) => value)
//                 : filterState.productType?.value,
//           },
//         }),
//         perPage: 1000,
//       });

//       const products = res.products;
//       if (isCheckAll) {
//         setSelected(res.products?.map((product) => product.sku));
//       }

//       return products;
//     },
//     [getAllProducts, modal?.excludeProductType, uniqueProductTypes, isCheckAll]
//   );

//   useEffect(() => {
//     handleRequest(async () => {
//       search?.applyClearSearch();
//       if (modal?.allProducts && modal?.allProducts?.length > 0) {
//         setAllProducts(modal?.allProducts || []);
//       } else {
//         const allProductsRes = await fetchAllProducts(initialFilterState());
//         setAllProducts(allProductsRes || []);
//         modal?.onFetch?.(allProductsRes);
//       }
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.allProducts]);

//   useEffect(() => {
//     if (modal?.active && modal?.value && modal?.value?.length >= 0) {
//       setAllProducts(modal?.allProducts || allProducts);
//       setSelected(modal?.value);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.value, modal?.active, modal?.allProducts]);

//   useEffect(() => {
//     if (modal?.active) {
//       setIsCheckAll(false);
//       search?.modifySearch("");
//       filter.applyClearFilter();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [modal?.active]);

//   const applyClearSearch = async () => {
//     setAllProducts(
//       await fetchAllProducts({
//         ...filter.filterState,
//         searchKey: "",
//         perPage: 1000,
//         id: "applyClearSearch",
//       })
//     );
//     search.applyClearSearch();
//   };

//   const applyFilter = async () => {
//     setAllProducts(
//       await fetchAllProducts({
//         ...filter.filterState,
//         searchKey: "",
//         perPage: 1000,
//         id: "all",
//       })
//     );
//     filter?.applyFilter();
//   };

//   const applySearch = async () => {
//     setAllProducts(
//       await fetchAllProducts({
//         ...filter.filterState,
//         searchKey: search.searchKey,
//         perPage: 1000,
//         id: "all",
//       })
//     );

//     search.applySearch();
//   };

//   const applyClearFilter = async () => {
//     setAllProducts(
//       await fetchAllProducts({
//         ...initialFilterState(),
//         searchKey: "",
//         perPage: 1000,
//         id: "all",
//       })
//     );

//     search.modifySearch("");
//     filter.applyClearFilter();
//   };

//   return (
//     <ConfirmModal
//       {...modal}
//       className={classNames(styles.modal, {
//         [`${styles.station}`]: modal?.fromStation,
//         [`${styles.promos}`]: !modal?.fromStation,
//       })}
//       primary={{
//         ...modal?.primary,
//         text: modal?.primary?.text || locale.save,
//         disabled: selected.length === 0,
//         onClick: onClickSave,
//       }}
//       secondary={{ ...modal?.secondary, text: locale.cancel }}
//     >
//       <SelectProductsFilter
//         {...filter}
//         {...search}
//         applyClearSearch={applyClearSearch}
//         applySearch={applySearch}
//         applyFilter={applyFilter}
//         applyClearFilter={applyClearFilter}
//         actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
//         actionProps={{
//           link: selected.length > 0,
//           variant: "text",
//           style: {
//             opacity: selected.length === 0 && 0.5,
//           },
//         }}
//         actionOnClick={() => {
//           setSelected([]);
//           setIsCheckAll(false);
//         }}
//         uniqueProductTypes={uniqueProductTypes}
//         placeholder={modal?.fromStation ? stationSearchFilter : promoSearchFilter}
//       />
//       <DataTableV2
//         {...table}
//         onCheckAll={handleOnCheckAll}
//         actionHeaderProps={{
//           checked: isCheckAll,
//         }}
//         size="small"
//       />
//     </ConfirmModal>
//   );
// };

// export default SelectProductsModal;

import { useMemo } from "react";
import ConfirmModal from "../confirm-modal/confirm-modal";
import SelectProductsFilter from "./select-products-filter";
import { initialFilterState } from "./select-products-filter-state";
import { productsColumn, stationProductsColumn } from "./select-products-columns";
import { mapDataToList, mapFilterToRequest } from "./select-products-mapper";
import { getProductsApi } from "apis";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import locale from "localization";
import styles from "./select-products-modal.module.scss";
import { prettifyProductType } from "utils/pretty.utils";
import classNames from "classnames";
import useSelectableItemsModal from "hooks/useSelectableItems";
import { excludeFilterAutoComplete } from "utils";

const SelectProductsModal = ({
  onFetch = (allProducts) => 0,
  allProducts = [],
  fromStation = false,
  coloredProduct = true,
  excludeProductType = [],
  value,
  onChange,
  ...modal
}) => {
  const uniqueProductTypes = useMemo(() => {
    const all = allProducts || [];
    const options = all.reduce((acc, { category, type }) => {
      if (!acc.some((p) => p.value === type)) {
        acc.push({ label: prettifyProductType(type), value: type });
      }
      return acc;
    }, []);

    return {
      options,
      filtered: options.filter(({ value }) => !excludeProductType?.includes(value)),
    };
  }, [allProducts, excludeProductType]);

  const dataTable = {
    api: {
      api: getProductsApi,
      mapper: (param) => param,
    },
    filter: {
      initialState: {
        ...initialFilterState(),
        productType: {
          isSelectedAll: !excludeProductType || excludeProductType?.length === 0,
          value: uniqueProductTypes.filtered.map(({ value }) => value),
        },
      },
      mapper: mapFilterToRequest,
    },
    table: {
      key: "products",
      mapper: (product, { onCheck, selected }) =>
        mapDataToList({
          product,
          selected: selected,
          coloredProduct: coloredProduct,
          onCheck: onCheck,
        }),
      columns: fromStation ? stationProductsColumn : productsColumn,
    },
  };

  const {
    selected,
    setSelected,
    handleOnCheckAll,
    setIsCheckAllState,
    isCheckAllState,
    dataTable: { table, filter, search },
  } = useSelectableItemsModal({
    active: modal?.active,
    uniqueKey: "sku",
    allItems: allProducts,
    value: value,
    dataTable,
    onFetchAllItemMapFilterState: (filterState) => {
      return {
        ...filterState,
        productType: excludeFilterAutoComplete({
          ...filterState.productType,
          exclude: excludeProductType,
          allOptions: uniqueProductTypes.options,
        }),
      };
    },
  });

  const promoSearchFilter = [locale.sku, locale.productName].join(", ");
  const stationSearchFilter = locale.productName;

  const onClickSave = () => {
    onChange?.(
      selected
        .map((name) => allProducts.find(({ sku }) => sku === name))
        .filter((product) => !!product)
    );
  };

  return (
    <ConfirmModal
      {...modal}
      className={classNames(styles.modal, {
        [`${styles.station}`]: fromStation,
        [`${styles.promos}`]: !fromStation,
      })}
      primary={{
        ...modal?.primary,
        text: modal?.primary?.text || locale.save,
        disabled: selected.length === 0,
        onClick: onClickSave,
      }}
      secondary={{ ...modal?.secondary, text: locale.cancel }}
    >
      <SelectProductsFilter
        {...filter}
        {...search}
        actionText={<locale.Populate text={locale.nSelected} items={[selected.length]} />}
        actionProps={{
          link: selected.length > 0,
          variant: "text",
          style: {
            opacity: selected.length === 0 && 0.5,
          },
        }}
        actionOnClick={() => {
          setSelected([]);
          setIsCheckAllState(false);
        }}
        uniqueProductTypes={uniqueProductTypes.filtered}
        placeholder={fromStation ? stationSearchFilter : promoSearchFilter}
      />
      <DataTableV2
        {...table}
        onCheckAll={handleOnCheckAll}
        actionHeaderProps={{
          checked: isCheckAllState,
        }}
        size="small"
      />
    </ConfirmModal>
  );
};

export default SelectProductsModal;
