/* eslint-disable array-callback-return */
import { Button, Product, Text, Title } from "components/commons";
import locale from "localization";
import styles from "../promo-details.module.scss";
import { FormMode, ProductCodeEnum } from "enums";
import { useCallback, useEffect, useMemo, useState } from "react";
import { InputAmount } from "components/field";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Validation from "utils/validation.utils";
import { useForm, useModal, useRouter } from "hooks";
import SelectProductsModal from "components/modals/select-products/select-products-modal";
import useProducts from "hooks/useProducts";
import { validateField } from "hooks/useForm";
import { roundUp } from "utils";
import { isNumber } from "lodash";

export const sortProductsBySku = (products, key = "sku") => {
  if (!products) return [];

  return products?.sort((a, b) => {
    const regex = /^(diesel|gas\d{2})/;

    const indexA = products.indexOf(a);
    const indexB = products.indexOf(b);

    if (indexA < 4 && indexB < 4) {
      return indexA - indexB;
    }

    if (indexA < 4) return -1;
    if (indexB < 4) return 1;

    return regex.test(b[key]) - regex.test(a[key]) || a[key].localeCompare(b[key]);
  });
};

const InputProducts = (rootForm = { onChangeIsFormSubmittable: (isFormSubmittable) => 0 }) => {
  const { onChangeIsFormSubmittable } = rootForm;
  const { allProducts } = useProducts();

  const router = useRouter();
  const pageMode = router.query?.mode || FormMode.Add;

  const selectProductModal = useModal();

  const amountFormat = "0.00";
  const props = useMemo(
    () => ({
      innerLabel: true,
      required: true,
      placeholder: amountFormat,
      max: 999999.99,
      min: 0.01,
      decimalPlaces: 2,
      maxLength: 9,
      isPriceValue: true,
      validations: [
        Validation.required(),
        Validation.maxlength(11),
        Validation.min(0.01, { isFormatted: true }),
      ],
    }),
    [amountFormat]
  );

  const DEFAULT_VALUE = useMemo(() => {
    let d = {
      dummy: {
        name: "dummy",
        value: 0.01,
      },
    };
    return d;
  }, []);

  const [formState, setFormState] = useState(null);

  const { fields, isFormSubmittable } = useForm({
    initialState: formState || DEFAULT_VALUE,
  });

  const iterateFormFields = useMemo(() => {
    if (!formState) return [];
    let products = [];
    Object.entries(formState).forEach(([sku, field]) => {
      if (sku !== "dummy") {
        products.push(field);
      }
    });

    return sortProductsBySku(products, "name");
  }, [formState]);

  const onChangeAmountInput = (name, { value }) => {
    setFormState((formState) => {
      let field = formState?.[name];
      if (!field) return formState;

      field.value = value;
      field = validateField(field, formState);

      return {
        ...formState,
        [name]: field,
      };
    });
  };

  const renderProductItem = (field, index) => {
    const { name, value } = field;
    const product = allProducts.find(({ sku }) => sku === name);

    return (
      <div key={`${name}-${index}`} className={styles.productInput}>
        <Product
          grass={name === ProductCodeEnum.GAS91}
          salsa={name === ProductCodeEnum.GAS95}
          deepBlue={name === ProductCodeEnum.GAS97}
          cheddar={name === ProductCodeEnum.DIESEL}
          purple={product?.category?.type === "dry-stock"}
        >
          {product.name}
        </Product>
        <Text>{product?.variant && product.variant !== "NULL" ? product?.variant : "--"}</Text>
        <InputAmount
          {...field}
          value={value}
          onChange={onChangeAmountInput}
          className={styles.amount}
        />
        <CloseIcon onClick={() => removeProductEntry(name)} className={styles.close} />
      </div>
    );
  };

  const removeProductEntry = useCallback(
    (sku) => {
      let temp = { ...formState };
      delete temp[sku];
      setFormState(Object.values(temp).length > 0 ? temp : DEFAULT_VALUE);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formState]
  );

  const openSelectionProduct = useCallback(() => {
    selectProductModal.show({
      title: locale.selectProducts,
    });
  }, [selectProductModal]);

  const handleProductEntries = useCallback(
    (entries = []) => {
      if (entries.length === 0) {
        return setFormState(DEFAULT_VALUE);
      }

      let arrayAsForm = {};
      entries.forEach(({ sku, name, type }, index) => {
        const existing = formState[sku];

        let newField = {
          name: sku,
          value: existing?.value ? roundUp(existing?.value, amountFormat) : "",
          label: existing?.label ? fields?.[sku]?.label : name,
          productType: type,
          ...props,
        };

        arrayAsForm = {
          ...arrayAsForm,
          [sku]: newField,
        };
      });

      if (Object.values(arrayAsForm).length === 0) {
        arrayAsForm = DEFAULT_VALUE;
      }

      selectProductModal.close();
      setFormState(arrayAsForm);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fields, iterateFormFields, allProducts]
  );

  const promoAsForm = useCallback(
    ({ sku, type, value, label }) => {
      const product = allProducts.find((product) => product.sku === sku);
      let formField = {
        name: sku,
        value: isNumber(value) ? roundUp(value, amountFormat) : "",
        productType: type || product?.type,
        label: label || product?.name,
        ...props,
      };

      return formField;
    },
    [allProducts, amountFormat, props]
  );

  // Monitor changes
  useEffect(() => {
    if (allProducts.length > 0 && formState) {
      rootForm.modifyField("product", {
        value: Object.values(formState)
          .filter((field) => field.name !== "dummy")
          .map((field) => ({
            sku: field.name,
            value: field.value,
            label: field.label,
            type: field.type,
          })),
      });
      onChangeIsFormSubmittable?.(isFormSubmittable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProducts, isFormSubmittable, formState]);

  // Initial State
  useEffect(() => {
    if (allProducts.length === 0 || formState) return;
    rootForm.modifyField("product", {}, ({ value: values }) => {
      let result = {};
      setFormState((formState) => {
        if (pageMode === FormMode.Edit && !formState) {
          values.forEach(({ sku, value, label, type }) => {
            const formField = promoAsForm({ sku, value, label, type });
            if (formField) {
              result = {
                ...result,
                [formField.name]: validateField(formField, formState),
              };
            }
          });
        }

        if (pageMode === FormMode.Add && !formState) {
          result = DEFAULT_VALUE;
          setFormState(DEFAULT_VALUE);
        }

        return Object.keys(result).length > 0 ? result : DEFAULT_VALUE;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageMode, allProducts, formState, amountFormat]);

  return (
    <div>
      <SelectProductsModal
        {...selectProductModal}
        value={iterateFormFields?.map((product) => product?.name)}
        onChange={handleProductEntries}
        allProducts={allProducts}
        coloredProduct={false}
      />
      <Title className={styles.subtitle}>{locale.product}</Title>
      <div className={styles.container}>
        <div>
          {iterateFormFields.length > 0 && (
            <>
              <div className={styles.flexCol}>
                {
                  // formState > fields (useForm) > iterateFormFields
                  // Mutate formState if any changes
                }
                <div className={styles.productInput}>
                  <div>{locale.productName}</div>
                  <div>{locale.variant}</div>
                </div>
                {iterateFormFields?.map((product, i) => renderProductItem(product, i))}
              </div>
              <br />
            </>
          )}
          <Button
            onClick={openSelectionProduct}
            startIcon={<AddIcon />}
            variant="outlined"
            className={styles.button}
          >
            {locale.addProduct}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InputProducts;
