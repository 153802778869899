import { InnerLabelTextfield, TextField } from "components/commons";
import { isNumber } from "lodash";
import React from "react";
import { isPesoValid, formatNumber, parseNumber, isAmountValid } from "utils";

const InputAmount = ({
  className,
  onChange,
  innerLabel,
  decimalPlaces = 2,
  isPriceValue = false,
  prefix = "₱",
  onBlur,
  ...props
}) => {
  const componentProps = {
    ...props,
    className,
    onChange: (name, { value }) => {
      if (props?.max && value > props.max) {
        return;
      }

      if (isPriceValue && isAmountValid(value, decimalPlaces)) {
        onChange(name, { value });
      }

      if ((!isPriceValue && isPesoValid(value, decimalPlaces)) || !value) {
        onChange(name, { value });
      }
    },
    onBlur: (name, { value }) => {
      if (onBlur) {
        onBlur(value);
      } else {
        if (value) {
          onChange(name, {
            value: `${formatNumber(value, decimalPlaces)}${!innerLabel ? "" : ""}`,
          });
        } else {
          // onChange(name, { value: `${formatNumber(0, decimalPlaces)}` });
        }
      }
    },
    onFocus: () => {
      if (props.value) {
        const num = Number(parseNumber(props.value));
        onChange(props.name, { value: isNumber(num) ? num : "" });
      }
    },
  };
  if (innerLabel) {
    return <InnerLabelTextfield {...componentProps} prefix={prefix} />;
  }
  return <TextField {...componentProps} />;
};

export default InputAmount;
