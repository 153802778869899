import React from "react";
import locale from "localization/en";
import styles from "../../station-details.module.scss";
import { Field, TextField } from "components/commons";
import { useFormContext } from "contexts/form-context";
import { RenderInput } from "../../station-details.module";
import { FormMode } from "enums";

const InputPosDetailsComponent = () => {
  const { fields, modifyField, formMode, initialState } = useFormContext();
  return (
    <div className={styles.container}>
      <Field
        label={locale.serialNumber}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields?.serialNumber}
          disabled={initialState?.serialNumber?.value && formMode === FormMode.Edit}
          inputDisabled={initialState?.serialNumber?.value && formMode === FormMode.Edit}
          onChange={(name, { value }) => {
            // Regex pattern for "000-000-000-00000" format
            const regex = /^[a-zA-Z0-9-]+$/;

            if (!value || regex.test(value)) {
              modifyField(name, { value: value });
            }
          }}
          Component={TextField}
        />
      </Field>
      <Field
        label={locale.machineIdNumber}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields?.machineIdentificationNo}
          disabled={initialState?.machineIdentificationNo?.value && formMode === FormMode.Edit}
          inputDisabled={initialState?.machineIdentificationNo?.value && formMode === FormMode.Edit}
          onChange={(name, { value }) => {
            // Regex pattern for "000-000-000-00000" format
            const regex = /^[a-zA-Z0-9-]+$/;

            if (!value || regex.test(value)) {
              modifyField(name, { value: value });
            }
          }}
          Component={TextField}
        />
      </Field>
      <Field
        label={locale.ptuNumber}
        labelPosition={"left-aligned"}
        labelClassName={styles.label}
        childrenClassName={styles.longContent}
      >
        <RenderInput
          {...fields?.ptuNumber}
          disabled={initialState?.ptuNumber?.value && formMode === FormMode.Edit}
          inputDisabled={initialState?.ptuNumber?.value && formMode === FormMode.Edit}
          onChange={(name, { value }) => {
            // Regex pattern for "000-000-000-00000" format
            const regex = /^[a-zA-Z0-9-]+$/;

            if (!value || regex.test(value)) {
              modifyField(name, { value: value });
            }
          }}
          Component={TextField}
        />
      </Field>
    </div>
  );
};

export default InputPosDetailsComponent;
