import React from "react";
import InputUgtsComponent from "./add-edit-components/input-ugts";

const UgtsComponent = ({ pageMode }) => {
  // let content;

  // const [ugts, setUgts] = useState([]);

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputUgtsComponent ugts={ugts} setUgts={setUgts} />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputUgtsComponent />;
  //     break;

  //   default:
  //     content = <ViewUgtsComponent />;
  //     break;
  // }

  return (
    <div>
      <InputUgtsComponent />
    </div>
  );
};

export default UgtsComponent;
