import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { Path } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useContext } from "react";
import { FeatureFlagContext } from "contexts";
import { SidebarImage, StocqLogo } from "images";
import { Image } from "components/commons";
import { PromoIcon, StationsIcon } from "../../../images";
import locale from "localization";

let Navigation = [
  {
    label: locale.stations,
    link: Path.Stations,
    icon: StationsIcon,
    exact: true,
  },
  {
    label: locale.promos,
    link: Path.PromosList,
    icon: PromoIcon,
    exact: true,
  },
];

const ChildItem = (props) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { styles, item } = props;

  return (
    <Accordion elevation={0} className={styles.accordionDetails} defaultExpanded={item.expanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
        <NavLink
          to={item.link}
          className={classNames(styles.listItem, styles.disableLink)}
          activeClassName={classNames(item, styles.selectedMenu)}
        >
          <span className={styles.item}>
            <span className={styles.listIconRoot}>
              <Image src={item.icon} className={styles.iconCustom} />
            </span>
            <span className={styles.listText}>{item.label}</span>
          </span>
        </NavLink>
      </AccordionSummary>
      <AccordionDetails>
        <div className={styles.child}>
          {item.items
            ?.filter((child) => {
              return child.key ? hasFeature(`PAGE_${child.key}`) : true;
            })
            .map((child, childKey) => {
              return (
                <NavLink
                  key={childKey}
                  to={child.link}
                  exact={child.exact}
                  className={classNames(styles.listItem)}
                  activeClassName={styles.active}
                >
                  <span className={styles.listText}>{child.label}</span>
                </NavLink>
              );
            })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);
  return (
    <div className={classNames(styles.sidebarContainer)}>
      <div className={styles.logoContainer}>
        <Image src={StocqLogo} className={styles.logo} />
      </div>
      <div className={styles.smallColorBox}></div>
      <List className={styles.sidebarList}>
        {Navigation.filter((item) => {
          return item.key ? hasFeature(`PAGE_${item.key}`) : true;
        }).map((item, index) => {
          return (
            <span key={index}>
              {item.exact ? (
                <NavLink
                  to={item.link}
                  exact
                  className={classNames(styles.listItem)}
                  activeClassName={styles.selectedMenu}
                >
                  <span className={styles.item}>
                    <span className={styles.listIconRoot}>
                      <Image src={item.icon} className={styles.iconCustom} />
                    </span>
                    <span className={styles.listText}>{item.label}</span>
                  </span>
                </NavLink>
              ) : (
                <ChildItem styles={styles} item={item} />
              )}
            </span>
          );
        })}
      </List>
      <div>
        <Image src={SidebarImage} className={styles.sidebar_image} />
      </div>
    </div>
  );
};

export default Sidebar;
