import React from "react";
import styles from "../../station-details.module.scss";
import useDataTable from "hooks/useDataTable";
import locale from "localization";
import classNames from "classnames";
import DataTableV2 from "components/commons/data-table/data-table-v2";
import { prettifyProductCode } from "utils/pretty.utils";
import { formatNumber } from "utils";
import { ProductCodeEnum } from "enums";
import { Product } from "components/commons";
const ViewUgtsComponent = ({ ugts }) => {
  const cols = [
    {
      key: "ugtNumber",
      text: locale.ugtNumber,
    },
    {
      key: "product",
      text: locale.product,
    },
    {
      key: "liters",
      text: locale.liters,
    },
  ];

  const mapDataToList = ({ ugt }) => {
    return {
      ugtNumber: `${locale.ugt} ${ugt?.id + 1}`,
      product: (
        <Product
          grass={ugt?.product === ProductCodeEnum.GAS91}
          salsa={ugt?.product === ProductCodeEnum.GAS95}
          deepBlue={ugt?.product === ProductCodeEnum.GAS97}
          cheddar={ugt?.product === ProductCodeEnum.DIESEL}
        >
          {prettifyProductCode(ugt?.product)}
        </Product>
      ),
      liters: formatNumber(ugt?.liters, 4) + " L",
    };
  };

  const { table } = useDataTable({
    value: ugts || [],
    filter: { initialState: {}, mapper: (fs) => fs },
    table: {
      key: "station-ugts",
      mapper: (ugt) => mapDataToList({ ugt }),
      columns: cols,
    },
  });

  return (
    <div className={classNames(styles.container, styles.viewUGTsTable)}>
      <DataTableV2 {...table} tableVersion="v2" hideNoSearchResultRender hidePagination />
    </div>
  );
};

export default ViewUgtsComponent;
