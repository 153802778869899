import React from "react";
import InputPromosComponent from "./add-edit-components/input-promos";

const PromosComponent = ({ pageMode }) => {
  // let content;

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputPromosComponent />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputPromosComponent />;
  //     break;

  //   default:
  //     content = <ViewPromosComponent />;
  //     break;
  // }

  return (
    <div>
      <InputPromosComponent />
    </div>
  );
};

export default PromosComponent;
