// FormContext.js
import { FormMode } from "enums";
import { useForm, useRouter } from "hooks";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

const FormContext = createContext(null);

export const FormProvider = ({
  initialState: initialFormState,
  validation,
  children,
  onChange,
}) => {
  const form = useForm({ initialState: initialFormState, validation });
  const [initialState, setInitialState] = useState(initialFormState);
  const [subFormsSubmittableState, setSubFormsSubmittableState] = useState({});

  const router = useRouter();
  const formMode = router.query?.mode || FormMode.Add;

  const isFormSubmittable = useMemo(() => {
    const subFormsState = Object.values(subFormsSubmittableState);
    if (subFormsState.length === 0) {
      return !!form.isFormSubmittable;
    }

    return !!form.isFormSubmittable && subFormsState.every((state) => !!state);
  }, [form.isFormSubmittable, subFormsSubmittableState]);

  useEffect(() => {
    onChange?.({
      ...form,
      isFormSubmittable,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.fields, isFormSubmittable]);

  useEffect(() => {
    if (initialFormState) setInitialState(initialFormState);
  }, [initialFormState]);

  return (
    <FormContext.Provider
      value={{
        ...form,
        isFormSubmittable,
        setSubFormsSubmittableState,
        formMode,
        initialState,
        setInitialState,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};
