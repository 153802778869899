import { ApiPath } from "enums";
import { Get, Post, Put } from "utils";

export const getUser = async () => {
  const res = await Get(`${ApiPath.GetUser}`);
  return res.data;
};

export const getUserV2 = async () => {
  const res = await Get(`${ApiPath.GetUser}/v2`);
  return res.data;
};

export const getUsers = async (query) => {
  const res = await Get(`${ApiPath.GetUsers}`, { ...query });
  return res.data;
};

export const createUser = async ({ profileType, email }) => {
  const res = await Post(`${ApiPath.GetUser}/create/${profileType}`, { email });
  return res.data;
};

export const updateUserStatus = async ({ ...body }) => {
  const res = await Put(`${ApiPath.GetUser}/status`, body);
  return res.data;
};

export const validateUsernameApi = async (query) => {
  const res = await Get(
    `${ApiPath.GetUser}/username?username=${encodeURIComponent(query.username)}&profileType=${query?.profileType || "pos"}`,
  );
  return res.data;
};

export const getStationUsersApi = async (query) => {
  const res = await Get(`${ApiPath.StationUsers}/${query?.stationId}`, query);
  return res.data;
};
