/* eslint-disable react-hooks/exhaustive-deps */
import TimePickerV2 from "./time-picker-v2";
import { useMemo } from "react";
import moment from "moment";
import { combineDateAndTimeInput } from "utils";
import styles from "./time-picker.module.scss";
import classNames from "classnames";
import Text from "../text/text";

export const isDifferentDay = (date1, date2) => {
  const d1 = moment(date1).format("YYYY-MM-DD");
  const d2 = moment(date2).format("YYYY-MM-DD");

  return d1 !== d2;
};

export const getMinTime = ({ startTime, endTime, minutesGap, currentTime, endDate }) => {
  if (isDifferentDay(startTime, endTime || endDate)) {
    return undefined;
  }

  if (!endTime) {
    if (startTime && startTime > currentTime) {
      return moment(startTime).add(minutesGap, "minutes").toDate();
    } else {
      return moment(currentTime).add(minutesGap, "minutes").toDate();
    }
  }

  if (startTime && startTime > endTime) {
    return moment(startTime).add(minutesGap, "minutes").toDate();
  }

  if (startTime && startTime > currentTime) {
    return moment(startTime).add(minutesGap, "minutes").toDate();
  }

  return moment(currentTime).add(minutesGap, "minutes").toDate();
};

const TimePickerRange = ({
  isView = false,
  startTimeProps,
  endTimeProps,
  minutesGap = 0,
  orientation = "horizontal",
  className,
  style,
  disableMinTime = false,
  ...props
}) => {
  const currentTime = new Date();

  const handleStartTimeChange = (name, { value }) => {
    let newStartTime = combineDateAndTimeInput(startTimeProps.date, value);
    const newMinTime = getMinTime({
      startTime: newStartTime,
      endTime: endTime,
      minutesGap,
      currentTime,
      endDate: endTimeProps?.date,
    });

    startTimeProps?.onChange?.(name, {
      value: newStartTime,
      minTime: newMinTime,
    });
  };

  const handleEndTimeOnChange = (name, { value, error }) => {
    const newEndTime = combineDateAndTimeInput(endTimeProps.date, value);
    endTimeProps?.onChange?.(name, { value: newEndTime, error });
  };

  const startTime = useMemo(() => {
    if (startTimeProps?.date && startTimeProps?.value) {
      return combineDateAndTimeInput(startTimeProps?.date, startTimeProps?.value);
    }

    if (startTimeProps?.value) {
      return startTimeProps?.value;
    }

    if (startTimeProps?.date) {
      return startTimeProps?.date;
    }
  }, [startTimeProps]);

  const endTime = useMemo(() => {
    if (!endTimeProps?.value) return null;

    if (endTimeProps?.date && endTimeProps?.value) {
      return combineDateAndTimeInput(endTimeProps?.date, endTimeProps?.value);
    }

    if (endTimeProps?.value) {
      return endTimeProps?.value;
    }

    if (endTimeProps?.date) {
      return endTimeProps?.date;
    }
  }, [endTimeProps]);

  const minOfEndTime = useMemo(() => {
    if (disableMinTime) return undefined
    
    const newMinTime = getMinTime({
      startTime,
      endTime,
      minutesGap,
      currentTime,
      endDate: endTimeProps?.date,
    });
    return newMinTime;
  }, [startTime, endTime, currentTime, minutesGap, startTimeProps, endTimeProps, disableMinTime]);

  return (
    <div
      className={classNames(
        orientation === "horizontal"
          ? styles.timeRangePickerHorizontal
          : styles.timeRangePickerVertical,
        className
      )}
      style={style}
    >
      <div>
        {isView ? (
          <Text color={"#000"}>{moment(startTimeProps?.value).format("hh:mm:ss a")}</Text>
        ) : (
          <TimePickerV2
            {...startTimeProps}
            value={startTime}
            onChange={handleStartTimeChange}
            onError={(error) => {
              startTimeProps?.onError?.(error);
            }}
          />
        )}
      </div>
      {orientation === "horizontal" && (
        <div style={{ textAlign: "center", alignContent: "center" }}>To</div>
      )}
      <div>
        {isView ? (
          <Text color={"#000"}>{moment(endTimeProps?.value).format("hh:mm:ss a")}</Text>
        ) : (
          <TimePickerV2
            {...endTimeProps}
            onChange={handleEndTimeOnChange}
            value={endTime}
            disabled={!startTime || endTimeProps?.disabled}
            minTime={minOfEndTime}
            onError={(error) => {
              endTimeProps?.onError?.(error);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TimePickerRange;
