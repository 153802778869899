import React from "react";
import InputDryStocksComponent from "./add-edit-components/input-dry-stocks";

const DryStocksComponent = ({ pageMode }) => {
  // let content;

  // switch (pageMode) {
  //   case FormMode.Add:
  //     content = <InputDryStocksComponent />;
  //     break;

  //   case FormMode.Edit:
  //     content = <InputDryStocksComponent />;
  //     break;

  //   default:
  //     content = <ViewDryStocksComponent />;
  //     break;
  // }

  return (
    <div>
      <InputDryStocksComponent />
    </div>
  );
};

export default DryStocksComponent;
