export const timePickerPurple = {
  ".MuiPickerStaticWrapper-content &.MuiPickersToolbar-root": {
    backgroundColor: "#5349b7 !important",
    display: "flex",
    justifyContent: "end",
    "& > span": {
      color: "#a79fff !important",
      fontSize: "10px",
    },
  },
  "& .MuiPickersToolbar-content": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100% !important",
    gap: "1em",
    "& > div": {
      margin: "0px",
    },
    "& .MuiTimePickerToolbar-hourMinuteLabel": {
      margin: "0px",
    },
  },
  "& .MuiTimePickerToolbar-separator": {
    color: "#a79fff !important",
    fontSize: "32px !important",
  },
  "& .MuiTimePickerToolbar-ampmSelection": {
    "& .Mui-selected": {
      color: "#a79fff !important",
      opacity: "1",
      border: "1px solid #5349b7",
      borderRadius: "8px",
      backgroundColor: "#251d6f",
      padding: "5px 15px !important",
    },
  },
  "& .MuiButtonBase-root": {
    "& .MuiTypography-root": {
      fontSize: "32px !important",
      color: "#ffffff !important",
      opacity: "0.5",
      fontWeight: "400",
      padding: "0px",
    },
    "& .Mui-selected": {
      color: "#ffffff !important",
      opacity: "1",
    },
    "& .MuiTimePickerToolbar-ampmLabel ": {
      fontSize: "10px !important",
      padding: "2px 5px !important",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
  },
  ".MuiPickerStaticWrapper-content &.css-7lip4c": {
    backgroundColor: "#5349b7 !important",
    background: "#5349b7 !important",
  },
};

export const timePickerDefault = {
  ".MuiPickerStaticWrapper-content &.MuiPickersToolbar-root": {
    backgroundColor: "#f5f6fa !important",
  },
  "& .MuiTimePickerToolbar-separator": {
    color: "#5349b7 !important",
  },
  "& .MuiButtonBase-root": {
    background: "#ebeef7 !important",
    color: "#5349b7 !important",
    "& .MuiTypography-root": {
      color: "#5349b7 !important",
      opacity: "0.5",
      fontSize: "36px !important",
      padding: "6px 15px 7px",
      fontWeight: "500",
    },
    "& .Mui-selected": {
      color: "#5349b7 !important",
      opacity: "1",
      border: "1px solid #5349b7",
      borderRadius: "8px",
    },
    "& .MuiTimePickerToolbar-ampmLabel ": {
      fontSize: "15px !important",
      padding: "2px 5px !important",
    },
  },
  "& .MuiIconButton-root": {
    display: "none",
  },
  ".MuiPickerStaticWrapper-content &.css-7lip4c": {
    backgroundColor: "#5349b7 !important",
    background: "#5349b7 !important",
  },
};
