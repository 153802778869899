const PaymentMethod = {
  Card: "card",
  Cash: "cash",
  GCash: "gcash",
  LOCQPay: "locqpay",
  SplitPayment: "split-payment",
  Maya: "maya",
  KeyAccount: "key-account",
  ShopeePay: "shopeepay",
  PLC: "plc",
  PLB: "plb",
  Other: "other",
  GiftCard: "gift-card",
  Check: "check",
  VIP: "vip-redemption",
};

export default PaymentMethod;
